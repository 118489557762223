<div class="modal-body" *ngIf="currentUser.userType === 'EMPLOYEE'" style="height: 100%;overflow-y: auto; color: #000;">
  <form [formGroup]="SaleInvoiceForm">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-8 mb-3 ps-0">
        <div class="card">
          <div style="display: flex;align-items: center;flex-wrap:wrap;">
            <div class="ps-1">
              <button type="button" class="btn my-1" style="width: 50px; height: 35px; background-color: #110152;">
                <img src="../../../../assets/icons/barcode.png" class="img-fluid" style="width:25px;height:25px;">
              </button>
            </div>
            <div style="flex-grow: 1;" class="search-product">
              <mat-form-field class="example-full-width my-1" appearance="outline">
                <input type="text" id="inp12" placeholder="Enter item name or scan barcode"
                  (input)="onSearchInputChange($event.target.value)" aria-label="Select Vendor Name" matInput
                  [formControl]="myControl1" [matAutocomplete]="auto">
                <mat-icon class="m-2" matSuffix>search</mat-icon>
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let option of filteredOptions2 | async" [value]="option.itemName"
                    (onSelectionChange)="getGoodsByBarcodeSelected(option.barcode,$event)">
                    <span style="font-size: 12px;">
                      {{ option?.itemName }}/<b>
                        {{ option?.barcode }}</b>
                    </span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="sale-icon">
              <button type="button" class="btn my-1"
                style="width: 75px; height: 35px; background-color: #F9C2AD; display: flex;justify-content:center; align-items: center;border-radius: 0px;">
                <!-- <img src="../../../../assets/icons/sale-icon.png" class="img-fluid" style="width:30px;height:25px;"> -->
                <span style="font-size: 14px; color: #000;">SALE</span>
              </button>
            </div>
            <div class="grid-btn">
              <button type="button" class="btn me-3 my-1"
                style="width: 100px; height: 35px; display: flex;justify-content:center;align-items:center; background-color: #110152;border-radius:0px;">
                <!-- <img src="../../../../assets/icons/grid.png" class="img-fluid" style="width: 25px; height: 25px; margin-right: 2px;"> -->
                <span style="font-size: 14px; color: #fff;">GRID</span>
              </button>
            </div>
          </div>
        </div>
        <div class="card" style="overflow-x: auto;">
          <table class="table table-responsive">
            <thead>
              <tr>
                <th style="width: 5%;text-align:center;">-</th>
                <th style="width: 35%;">Item Name</th>
                <th style="width: 25%;">Price</th>
                <th style="width: 10%;">Qty.</th>
                <th style="width: 10%;">Discount</th>
                <th style="width: 25%;text-align:right;">Total</th>
              </tr>
            </thead>
            <tbody>
              <ng-container formArrayName="subcheckbox"
                *ngFor="let items of formArr.controls;let item of productData;let i=index">
                <tr [formGroupName]="i">
                  <td>
                    <button type="button" class="text-center btnb" style="width: 3rem;"
                      (click)="changeQuantity('rm',i)">
                      <span class="bi-trash icon-color fs-1" style="color:red;" data-bs-toggle="tooltip"
                        data-bs-placement="top" title="Delete"></span>
                    </button>
                  </td>
                  <!-- <td style="vertical-align: middle;">{{ i + 1 }}</td> -->
                  <!-- <td>
                  {{ item.barcode }}
                </td> -->
                  <td>{{ item?.itemName | titlecase }}</td>
                  <!-- <td class="text-center">
                  <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit" readonly />
                </td> -->
                  <td colspan="1">
                    <p class="mb-0">
                      ₹&nbsp;{{ item?.basePrice | number:'1.2-2' }}
                    </p>
                  </td>
                  <td
                    style="display:flex; flex-direction: row; justify-content: center; align-items: center; padding: 14px 9px 13px 9px;">
                    <a class="border-white btn mb-0" style="margin-top: 0px ;padding: 0px;"
                      (click)="changeQuantity('-',i)">-</a>
                    <input type="text" matInput class="mb-0" [value]="item.quantity" placeholder="Quantity"
                      (change)="changeQuantity('qty',i,$event.target.value)" style="width: 5rem; text-align: center;" />
                    <a class="border-white btn mb-0" style="margin-top: 0px; padding: 0px;"
                      (click)="changeQuantity('+',i)">+</a>
                  </td>
                  <td colspan="1">
                    <p class="mb-0">
                      {{ item?.offerData?.discountPercentage || 0 | number:'1.2-2' }} %
                    </p>
                  </td>
                  <!-- <td colspan="1">
                  <p class="">
                    {{ item.gstRate }}%
                  </p>
                </td> -->
                  <!-- <td colspan="1" style="max-width: 150px;">
                  <p class="">
                    ₹&nbsp;{{item.gstAmount | number:'1.2-2'}}
                  </p>
                </td> -->
                  <td>
                    <p class="mb-0" style="max-width: 150px;text-align:right;">
                      ₹&nbsp;{{item?.total | number:'1.2-2'}}
                    </p>
                  </td>
                </tr>
                <tr *ngIf="item.isOffer" class="promotion-row" style="color: #000;">
                  <td colspan="1">
                  </td>
                  <td colspan="2" style="text-align: left;">
                    {{ item.offerData?.schemaName}}
                  </td>
                  <!-- <td colspan="2" class="text-success" style="text-align: right;">
                  ₹&nbsp; - {{ (item?.gstAmount - item.offerData?.originalGst) | number:'1.2-2' }}
                </td> -->
                  <td colspan="3" style="text-align: right;">
                    ₹&nbsp; - {{ (item?.total - item.offerData?.totalDiscountedPrice) | number:'1.2-2' }}
                  </td>
                  <!-- <td></td> -->
                </tr>
                <tr *ngIf="item.isFreeProduct">
                  <td colspan="1">
                  </td>
                  <td colspan="1" style="text-align: left;">
                    {{ item?.schemaName | uppercase }}
                  </td>
                  <td colspan="2" style="text-align: right;padding-right: 46px;">
                    {{ item?.freeProductQuantity}}
                  </td>
                  <!-- <td colspan="1" class="text-success" style="text-align: right;">
                  ₹&nbsp; - {{ item?.freeProductTotalGst | number:'1.2-2' }}
                </td> -->
                  <td colspan="2" style="text-align:right;">
                    ₹&nbsp;- {{ item?.freeProductTotalPrice | number:'1.2-2' }}
                  </td>
                  <!-- <td></td> -->
                </tr>
              </ng-container>
            </tbody>
            <tbody *ngIf="formArr.controls.length === 0 && productData === 0">
              <tr class="text-center">
                <!-- <td style="width:100px;">&nbsp;</td> -->
                <td colspan="6">
                  <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
                    alt="notfound1" width="200" height="150" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-4 pe-0">
        <div class="card">
          <!-- <div style="display: flex; align-items: center; justify-content: center; gap: 2rem; padding: 20px 10px;">
            <div>
              <div class="my-1" *ngIf="!showInvoiceGenerateData">
                <div class="d-flex" style="font-size: 14px;">
                  <div class="form-check padding-left">
                    <input class="" style="margin-left: 0px" id="inlineCheckbox1" type="checkbox" formControlName="cashSale"
                      (change)="isCashSaleToggle($event.target.checked)" [disabled]="showInvoiceGenerateData" />
                  </div>
                  <label class="text-nowrap saleText form-label" style="margin-left:4px">Cash </label>
                </div>
              </div>
              </div>
              <div>
                <button type="button" class="btn btn-cancel my-1"  (click)="onCancelModal()">Cancel Sale</button>
              </div>
          </div> -->
          <!-- <div class="dropdown-divider"></div> -->
          <div>
            <div
              style="display: flex; align-items: center; justify-content: flex-end;gap:1rem;width: 99%;margin-right:4px;"
              class="mt-3 ps-3">
              <!-- <button type="button" class="btn" style="border: 1px solid black;font-size: 14px;">...</button> -->
              <div style="flex: 0 0 auto;">
                <button type="button" class="btn"
                  style="width: 100px; height: 34px; color: #fff; background-color: #110152;" (click)="onCancel()">
                  <span>Clear</span>
                </button>
              </div>
              <div style="flex: 0 0 auto;">
                <button [matMenuTriggerFor]="menu" class="btn" type="button"
                  style="height: 34px; width: 36px; background-color: #110152; color: #fff;">
                  <i class="fas fa-ellipsis-v"></i>
                </button>
                <mat-menu #menu="matMenu">
                  <!-- <button mat-menu-item>
                    <mat-icon>check</mat-icon>
                    <span>Suspended sale</span>
                  </button> -->
                  <!-- <button mat-menu-item>
                    <mat-icon>check</mat-icon>
                    <span>Batch Sale</span>
                  </button> -->
                  <button mat-menu-item (click)="onShowLastSaleReceiptClick()"
                    *appHasPermission="['read']; name 'sales'" data-bs-toggle="modal" data-bs-target="#invoice-modal2">
                    <mat-icon>check</mat-icon>
                    <span>Show last sale receipt</span>
                  </button>
                  <button mat-menu-item (click)="openCustomerFacingDisplay()">
                    <mat-icon>check</mat-icon>
                    <span>Customer facing display</span>
                  </button>
                  <!-- <button mat-menu-item>
                    <mat-icon>check</mat-icon>
                    <span>Pop Open cash drawer</span>
                  </button> -->
                  <button mat-menu-item (click)="openDialogPayment(true)">
                    <mat-icon>check</mat-icon>
                    <span>Add cash to register</span>
                  </button>
                  <button mat-menu-item (click)="openDialogPayment(false)">
                    <mat-icon>check</mat-icon>
                    <span>Remove Cash to register</span>
                  </button>
                  <button mat-menu-item (click)="openCloseRegister()">
                    <mat-icon>check</mat-icon>
                    <span>Close register</span>
                  </button>
                  <!-- <button mat-menu-item>
                    <mat-icon>check</mat-icon>
                    <span>Enable test mode</span>
                  </button> -->
                  <button mat-menu-item (click)="customFieldConfiguration()">
                    <mat-icon>check</mat-icon>
                    <span>Custom field configuration</span>
                  </button>
                </mat-menu>
              </div>
            </div>
            <div
              style="display: flex; align-items: center; justify-content: space-between; width: 100%; margin-top: 4px;"
              class="ps-3">
              <div class="ps-1">
                <button type="button" class="btn my-1" style="width: 50px; height: 35px; background-color: #110152;">
                  <img src="../../../../assets/icons/user.png" class="img-fluid" style="width:25px;height:25px;">
                </button>
              </div>
              <mat-form-field style="font-size: 11px;" class=" example-full-width" appearance="outline">
                <input type="text" placeholder="Customer Name" ngxOnlyString aria-label="Select Vendor Name" matInput
                  [formControl]="myControl" required (keyup)="searchPartyByName($event.target.value)"
                  [matAutocomplete]="autoCustomer" [ngClass]="{
                          'is-invalid':
                            myControl.invalid && myControl.touched && submitted
                        }">
                <mat-autocomplete #autoCustomer="matAutocomplete">
                  <!-- <mat-option *ngIf="currentUser.userType !== 'SHOP' && currentUser.userType !== 'WAREHOUSE'">
                    <a class="mobVerify" style="display: inline-block; width: 100%;" aria-hidden="true"
                      title="Add new Customer" (click)="openAddPartyModal(addParty1)">
                      Add new Customer
                    </a>
                  </mat-option> -->
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
                    (onSelectionChange)="setSelectedPartyDeatails(option)">
                    {{ option?.partyName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
               <mat-error class="text-danger" *ngIf="
                    (submitted || myControl.touched) && myControl.errors?.required
                  ">
                Customer Is Required.
              </mat-error>
            </div>

            <div
              style="display: flex; align-items: center; justify-content: space-between; width: 100%; margin-top: 4px;"
              class="ps-3">
              <div class="ps-1">
                <button type="button" class="btn my-1" style="width: 50px; height: 35px; background-color: #110152;">
                  <img src="../../../../assets/icons/tax.png" class="img-fluid" style="width:25px;height:25px;">
                </button>
              </div>

              <mat-form-field style="font-size: 11px" class="example-full-width" appearance="outline">
                <input type="text" matInput [textMask]="{mask: mask}" placeholder="Enter e-way Bill #"
                  formControlName="ewayBillNo" [disabled]="showInvoiceGenerateData"
                  [ngClass]="{ 'is-invalid': (SaleInvoiceForm.controls.ewayBillNo.invalid && SaleInvoiceForm.controls.ewayBillNo.touched) && submitted }">
              </mat-form-field>

              <mat-error class="text-danger"
                *ngIf="(submitted || SaleInvoiceForm.controls.ewayBillNo.touched) && SaleInvoiceForm.controls.ewayBillNo.errors?.required">
                e-Way Bill # is required when the total is ₹50,000 or more!
              </mat-error>
            </div>

            <div
              style="display: flex; align-items: center; justify-content: space-between; width: 100%; margin-top: 4px;"
              class="ps-3">
              <div class="ps-1">
                <button type="button" class="btn my-1" style="width: 50px; height: 35px; background-color: #110152;">
                  <img src="../../../../assets/icons/tax.png" class="img-fluid" style="width:25px;height:25px;">
                </button>
              </div>
              <mat-form-field style="font-size: 11px;" class="example-full-width" appearance="outline">
                <input type="text" placeholder="Enter Tax Invoice #" matInput formControlName="invoiceNo" maxlength="20"
                  [disabled]="showInvoiceGenerateData" [ngClass]="{
                  'is-invalid': (f.invoiceNo.invalid && f.invoiceNo.touched) && submitted }">
              </mat-form-field>
              <mat-error class="text-danger" *ngIf="(submitted || f.invoiceNo.touched) && f.invoiceNo.errors?.required">
                Tax Invoice Is Required.
              </mat-error>
            </div>
          </div>
        </div>
        <div class="card mt-2 p-2">
          <div *ngIf="!showInvoiceGenerateData">
            <div *ngIf="!showInvoiceGenerateData && showCounterDiscountField" class="d-flex justify-content-start">
              <p class="counter-text p-2 mb-0" (click)="getCounterDiscountAmount()" *ngIf="!counterOn"
                style="color: #5DBD44;">Apply Counter Discount</p>
            </div>
            <div *ngIf="!showInvoiceGenerateData && showCounterDiscountField" class="d-flex justify-content-start">
              <p class="counter-text p-2 mb-0" (click)="openCouponModal()" *ngIf="!couponApplied"
                style="color: #5DBD44;">
                <i class="fas fa-tags"></i> Apply Coupon
              </p>
            </div>
            <div class="d-flex justify-content-start">
              <p class="counter-text p-2 mb-0" (click)="giftCard()" style="color: #5DBD44;"
                *ngIf="(f.paymentMode.value === 'GIFTCARD') && !giftCardApplied">
                <i class="fas fa-tags"></i> Apply Gift Card
              </p>
            </div>
            <div class="p-2"
              style="display: flex; justify-content: space-between; background-color: #FFF0EA; color: #000;">
              <div>Subtotal</div>
              <div *ngIf="amtToMinusFromTotal === 0 || null || undefined">₹&nbsp;{{ subTotal | number:'1.2-2' }}</div>
              <div *ngIf="amtToMinusFromTotal !== 0 || null || undefined">₹&nbsp;{{ (subTotal || 0) -
                ((amtToMinusFromTotal - newGstRate) || 0) | number:'1.2-2' }}</div>
            </div>
            <!-- <div *ngIf="amtToMinusFromTotal !== 0 || null || undefined" class="p-2" style="display:flex;justify-content: space-between;background-color: #FFF0EA; color: #5DBD44;">
              <div>Subtotal</div>
              <div *ngIf="amtToMinusFromTotal !== 0 || null || undefined">(₹&nbsp;{{ - (amtToMinusFromTotal - newGstRate) | number:'1.2-2'}})</div>
            </div> -->
            <div class="p-2" style="display:flex;justify-content: space-between;">
              <div>Tax</div>
              <div *ngIf="(newGstRate === 0 || null || undefined)">₹&nbsp;{{ gstRate | number:'1.2-2' }}</div>
              <div *ngIf="(newGstRate !== 0 || null || undefined)">₹&nbsp;{{ (gstRate - newGstRate) || 0 |
                number:'1.2-2' }}</div>
            </div>
            <!-- <div class="p-2" *ngIf="(newGstRate !== 0 || null || undefined)" class="" style="display: flex; justify-content: space-between; color: #5DBD44;">
              <div>Discounted Tax</div>
              <div>(₹&nbsp;{{ - newGstRate | number:'1.2-2'}})</div>
            </div> -->
            <div class="p-2"
              style="display:flex;justify-content: space-between; background-color: #FFF0EA; color: #000;">
              <div>Total</div>
              <div *ngIf="(amtToMinusFromTotal === 0 || null || undefined)">₹&nbsp;{{ total || 0 | number:'1.2-2' }}
              </div>
              <div *ngIf="(amtToMinusFromTotal !== 0 || null || undefined)">₹&nbsp;{{ ((total || 0) -
                (amtToMinusFromTotal || 0)) || 0 | number:'1.2-2' }}
              </div>
              <!-- <div class="p-2" *ngIf="(amtToMinusFromTotal !== 0 || null || undefined) && !showInvoiceGenerateData && !showCounterDiscountField" class="" style="display:flex;justify-content: space-between;padding:10px 5px;background-color: #FFF0EA; color: #5DBD44;">
              <div>Discounted Total</div> -->
              <!-- <div *ngIf="(amtToMinusFromTotal !== 0 || null || undefined && f.counterDiscount.value === (0 || null || undefined || ''))"> -->
              <!-- <div *ngIf="(amtToMinusFromTotal !== 0 || null || undefined)">₹&nbsp;{{ ((total || 0) - (amtToMinusFromTotal || 0)) || 0 | number:'1.2-2'}}
              </div> -->
            </div>
          </div>

          <div>
            <table *ngIf="!showInvoiceGenerateData && showCounterDiscountField"
              class="table table-bordered  my-custom-scrollbar" style="color: #5DBD44;">
              <tr *ngIf="counterOn">
                <td class="thWidth p-2" style="text-align: left; color: #5DBD44;">Counter Discount</td>
                <td class="p-0 " style="text-align: right; color: #5DBD44;">
                  <span (click)="removeCounter()" class="text-danger" style="cursor: pointer;">Remove</span>
                  <mat-form-field style="font-size: 12px; width: 50%;" class="p-0 m-0" appearance="outline">
                    <span matPrefix>₹ -</span>
                    <input type="text" placeholder="Counter Discount" (change)="calculationTotal()" matInput
                      formControlName="counterDiscount" (keypress)="numberOnly($event)">
                  </mat-form-field>
                </td>
              </tr>
              <tr *ngIf="couponApplied">
                <td class="thWidth p-2" style="text-align: left; color: #5DBD44;">Coupon Discount</td>
                <td class="p-0" style="text-align: right; color: #5DBD44;">
                  <ng-container *ngIf="!couponApplied; else appliedCouponBlock" (click)="openCouponModal()">
                    Apply Coupon
                  </ng-container>
                  <ng-template #appliedCouponBlock>
                    <span (click)="removeCoupon()" class="text-danger" style="cursor: pointer;">Remove</span>
                    <span>₹&nbsp;- {{ appliedCoupon?.discount_amount | number:'1.2-2' }}</span>
                  </ng-template>
                </td>
              </tr>
              <tr *ngIf="billFlatOffer?.discountAmount !== null && billFlatOffer?.discountAmount !== undefined">
                <td class="thWidth p-2" style="text-align: left; color: #5DBD44;">Flat Discount
                  -{{billFlatOffer?.schemaName}}
                </td>
                <td class="p-2" style="width: 50px; text-align: right; color: #5DBD44;">
                  ₹&nbsp;{{ billFlatOffer?.discountAmount | number:'1.2-2'}}
                </td>
              </tr>
              <!-- <tr>
                <td class="thWidth p-2" style="text-align: left;font-size:14px;">Final Total</td>
                <td class="p-2" *ngIf="billFinalTotal !== null && billFinalTotal !== undefined"
                  style="width: 50px; text-align: right;">
                  ₹&nbsp;{{ ((billFinalTotal || 0) - (billFlatOffer?.discountAmount || 0 )) | number:'1.2-2'}}
                </td>
              </tr> -->
              <tr *ngIf="giftCardApplied">
                <td class="thWidth p-2" style="text-align: left; color: #5DBD44;">Gift Card Code
                </td>
                <td class="p-0" style="text-align: right; color: #5DBD44;">
                  <span (click)="removeGiftCard()" class="text-danger" style="cursor: pointer;">Remove</span>
                  <mat-form-field style="font-size: 12px; width: 50%;" class="p-0 m-0" appearance="outline">
                    <input type="text" placeholder="Gift Card Code" (input)="giftcard_codeInput($event)" matInput
                      formControlName="giftcard_code" maxlength="10">
                  </mat-form-field>
                </td>
              </tr>
            </table>
            <!-- <div *ngIf="!showInvoiceGenerateData && showCounterDiscountField" class="dropdown-divider"></div> -->
            <table *ngIf="!showInvoiceGenerateData && showCounterDiscountField" class="table table-responsive">
              <tbody>
                <tr>
                  <td style="border-right: 1px solid #FFF0EA;">
                    <div style="display: flex;flex-direction: column;">
                      <div class="p-2" style="text-align: left;font-size: 16px;color:#000;font-weight: 500;">
                        Total
                      </div>
                      <div *ngIf="billFinalTotal !== null && billFinalTotal !== undefined" class="p-2"
                        style="text-align: right;font-size: 15px;color: green;font-weight: 700;">
                        ₹&nbsp;{{ ((billFinalTotal || 0) - (billFlatOffer?.discountAmount || 0 )) | number:'1.2-2'}}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div style="display: flex;flex-direction: column;">
                      <div class="p-2" style="text-align: left;font-size: 16px;color: black;font-weight: 500;">
                        Amount Due
                      </div>
                      <div class="p-2" style="text-align: right;font-size: 15px;color: orange;font-weight: 700;">
                        ₹&nbsp;{{ changeDue || 0 | number:'1.2-2'}}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="!showInvoiceGenerateData && showCounterDiscountField" class="dropdown-divider"></div>
            <div style="padding: 10px 5px;" *ngIf="SaleInvoiceForm.get('currency')">
              <mat-label class="form-label">Payment Currency</mat-label>
              <mat-form-field style="font-size: 12px;" class="example-full-width" appearance="outline">
                <input type="text" placeholder="Enter payment currency" matInput maxlength="20"
                  formControlName="currency" value="₹">
              </mat-form-field>
            </div>
            <div class="dropdown-divider"></div>
            <div class="mt-3">
              <label style="font-size: 14px;color: black;margin-left: 5px;">Add Payment</label><br>

              <button type="button" class="btn paymentMode mx-2 my-1" (change)="isCashSaleToggle($event.target.checked)"
                [ngClass]="{'selected-mode': SaleInvoiceForm.get('paymentMode')?.value === 'CASH'}"
                (click)="setPaymentMode('CASH')">
                Cash
              </button>

              <button type="button" class="btn paymentMode mx-1 my-1"
                [ngClass]="{'selected-mode': SaleInvoiceForm.get('paymentMode')?.value === 'GIFTCARD'}"
                (click)="setPaymentMode('GIFTCARD')">
                Gift Card
              </button>

              <!-- <button type="button" class="btn paymentMode mx-2 my-1"
                [ngClass]="{'selected-mode': SaleInvoiceForm.get('paymentMode')?.value === 'CREDITCARD'}"
                (click)="setPaymentMode('CREDITCARD')">
                Credit Card
              </button> -->
            </div>
            <div style="margin: 8px 0px;display:flex;align-items: center;flex-wrap: wrap;">
              <div *ngIf="!showInvoiceGenerateData && showCounterDiscountField" style="flex-grow: 1;">
                <mat-form-field style="font-size: 12px;" class="example-full-width" appearance="outline">
                  <span matPrefix>₹ -</span>
                  <input type="number" placeholder="Amount Paid by User" (keypress)="numberOnly($event)"
                    (input)="restrictAmountInput($event)" matInput formControlName="amountByUser" maxlength="10"
                    min="0">
                </mat-form-field>
                <mat-error *ngIf="SaleInvoiceForm.get('amountByUser').hasError('minimumAmount')">
                  Amount must be at least {{ SaleInvoiceForm.get('amountByUser').errors?.minimumAmount.requiredAmount }}
                  ₹.
                </mat-error>
              </div>
              <div>
                <button type="button" class="btn btn-save ms-2" (click)="postSaleInvoice()"
                  *appHasPermission="['create']; name 'sales'">Complete Sale</button>
              </div>
            </div>
            <div class="dropdown-divider"></div>
            <div *ngIf="SaleInvoiceForm.get('comment')" style="margin: 4px;">
              <textarea rows="3" style="width: 100%;" placeholder="Add comments" formControlName="comment"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add New Party Modal -->
    <ng-template #addParty1 let-modal>
      <div class="modal-content position-relative">
        <div class="position-absolute top-0 end-0 mt-2 me-2 options">
          <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" (click)="modalDismiss()"
            aria-label="Close"></a>
        </div>
        <div class="modal-body p-0">
          <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
            <h4 class="mb-1" id="modalExampleDemoLabel" style="font-size: 16px;font-weight:500;font-family: 'Poppins';">
              Add New Customer
            </h4>
          </div>
          <form [formGroup]="PartyForm">
            <div class="p-4 pb-0">
              <div class="form-check form-switch mb-0 lh-1 mb-3">
                <input class="form-check-input" type="checkbox" [checked]="isShowGstFields" id="flexSwitchCheckDefault"
                  (click)="customertoggleShow($event.target.checked)" />
                <label class="lab text-nowrap form-label p-1" style="font-size: 14px;"> {{ isShowGstFields ? 'As
                  Customer' : 'As Vendor' }} </label>
              </div>

              <div class="row d-flex justify-content-start" *ngIf="!isShowGstFields">

                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <div class="mb-3">
                    <label class="form-label example-full-width">GSTIN<span class="error-asterisk">*</span></label>
                    <mat-form-field appearance="outline" style="min-width: calc(85% + 13px)!important;">
                      <input matNativeControl formControlName="gstIn" (change)="getGstNumber($event.target.value)"
                        maxlength="15" minlength="15" autocomplete="off"
                        pattern="^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$"
                        placeholder="GSTIN" style="text-transform: uppercase"
                        [ngClass]="{'is-invalid': f.gstIn.invalid && f.gstIn.touched}" required />
                    </mat-form-field>
                    <mat-spinner *ngIf="isLoading" diameter="30"></mat-spinner>
                    <mat-icon *ngIf="PartyForm.controls.gstIn.valid && !isLoading"
                      [ngClass]="{ 'gst-status-icon': true, 'active': !isDisabledGST }">
                      {{ isDisabledGST ? '✗' : '✓' }}
                    </mat-icon>
                    <mat-error class="text-danger"
                      *ngIf="(submitted || f.gstIn.touched) && (f.gstIn.errors?.required || f.gstIn.errors?.pattern || f.gstIn.errors?.minlength || f.gstIn.errors?.maxlength)">
                      Enter a valid GSTIN.
                    </mat-error>
                  </div>
                </div>

              </div>

              <div class="row d-flex justify-content-start">
                <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label class="form-label">Customer Name<span class="error-asterisk">*</span></label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl matInput placeholder="Customer Name" formControlName="partyName"
                      ngxOnlyString [ngClass]="{
                      'is-invalid': (pf.partyName.invalid && pf.partyName.touched) && submittedParty }">
                  </mat-form-field>
                  <mat-error class="text-danger" *ngIf="(submittedParty || pf.partyName.touched)">
                    <mat-error *ngIf="(submittedParty || pf.partyName.touched) && pf.partyName.errors?.required">
                      Customer Name Is Required.
                    </mat-error>
                  </mat-error>

                </div>
                <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label class="form-label">Mobile #<span class="error-asterisk">*</span></label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" placeholder="Mobile #" matInput formControlName="mobile" minlength="10"
                      maxLength="10" ngxOnlyNumbers [ngClass]="{
                        'is-invalid': (pf.mobile.invalid && pf.mobile.touched) && submittedParty }">
                  </mat-form-field>

                  <mat-error class="text-danger"
                    *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.pattern">
                    Enter a valid mobile #.
                  </mat-error>
                  <mat-error class="text-danger"
                    *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.required">
                    Mobile # is required.
                  </mat-error>
                  <mat-error *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.maxlength">
                    Mobile # must be of 10-digits.
                  </mat-error>
                  <mat-error *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.minlength">
                    Mobile # must be of 10-digits
                  </mat-error>
                </div>
                <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label class="form-label">E-Mail<span class="error-asterisk">*</span></label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <input type="email" placeholder="E-Mail" matInput formControlName="email" [ngClass]="{
                        'is-invalid': (pf.email.invalid && pf.email.touched) && submittedParty }">
                  </mat-form-field>
                  <mat-error class="text-danger"
                    *ngIf="(submittedParty || pf.email.touched) && pf.email.errors?.pattern">
                    Enter a valid E-Mail.
                  </mat-error>
                  <mat-error class="text-danger"
                    *ngIf="(submittedParty || pf.email.touched) && pf.email.errors?.required">
                    E-Mail is required.
                  </mat-error>
                </div>
              </div>

              <div class="row d-flex justify-content-start">
                <div class="col-lg-4 col-sm-12 col-md-6">
                  <label class="form-label">Billing Address<span class="error-asterisk">*</span></label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput formControlName="billingAddress" maxlength="150" placeholder="Enter your address"
                      [ngClass]="{
                        'is-invalid': (pf.billingAddress.invalid && pf.billingAddress.touched) && submittedParty }"></textarea>
                  </mat-form-field>
                  <mat-error class="text-danger" *ngIf="(submittedParty || pf.billingAddress.touched)">
                    <mat-error
                      *ngIf="(submittedParty || pf.billingAddress.touched) && pf.billingAddress.errors?.required">
                      Billing Address Is Required.
                    </mat-error>
                  </mat-error>
                </div>

                <div class="col-lg-4 col-sm-12 col-md-6" *ngIf="isShippingAddressinModal">
                  <label class="form-label">Shipping Address</label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput formControlName="shippingAddress" placeholder="Enter your address"></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-check form-check-inline mb-2">
                <label class="form-check-label form-label fs--1" for="inlineCheckbox1"><span
                    style="font-size: 14px;">Add Shipping Address</span></label>
                <input class="form-check-input fs--1" id="inlineCheckbox1" type="checkbox"
                  [checked]="isShippingAddressinModal" (change)="showShippingAddressinModal($event.target.checked)" />
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-cancel" (click)="modalDismiss()">Close</button>
          <button class="btn btn-save" (click)="addNewParty()" data-bs-dismiss="modal">Add
          </button>
        </div>
      </div>
    </ng-template>

  </form>
</div>



<div class="modal modal-invoice fade" #closebutton id="invoice-modal2" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content position-relative">
      <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
        <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
          id="closeModalPrint">
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="container">
          <div class="card" style="box-shadow: none !important;">
            <div class="card-body">

              <div class="flex-container" style="margin-top: 16px;">

                <div class="flex-row" style="display: flex;margin: 8px 0px;">
                  <div class="row-left" style="display: flex;flex-direction: column;flex-basis: 50%;">
                    <!-- <span style = "color: red;font-weight: 600;margin:5px 0px 12px">{{tradeName ? tradeName : "NA"}}</span> -->
                    <span style="font-weight: 600;margin:5px 0px 12px" [style.color]="selectedColor" #dynamicText
                      [style.fontSize.px]="getTextSize(selectedTextSize)">{{tradeName ? tradeName : "NA"}}</span>
                    <span>Contact:&nbsp;{{ mobile ? mobile : 'NA'}}</span>
                  </div>
                  <div class="row-right" style="display: flex;flex-direction:column;flex-basis:50%;">
                    <div class="right-content" style="display: flex;align-items: center;">
                      <div class="content-length" style="flex-basis: 30%;">
                        <span style="font-weight: 600;">TAX INVOICE</span>
                      </div>
                      <div class="content-length" style="flex-basis: 40%;">
                        <button style="float: right;font-size: 16px ;font-weight: bold;padding-right: 0px !important;"
                          id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0" type="button"
                          (click)="thermalPrint()"><span class="fas fa-print me-1">
                          </span>Thermal Print
                        </button>
                      </div>
                      <div class="content-length" style="flex-basis: 30%;">
                        <button style="float: right;font-size: 16px ;font-weight: bold;padding-right: 0px !important;"
                          id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0" type="button"
                          (click)="onPrint()"><span class="fas fa-print me-1">
                          </span>Print
                        </button>
                        <!-- <div id="originalForReceiptPrint">
                          ORIGINAL FOR RECIPIENT
                        </div> -->
                      </div>
                    </div>
                    <div class="right-content" style="display: flex;align-items: center;margin-bottom:4px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        Invoice #<span style="float: right;">:</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        {{invoiceNo}}
                      </div>
                    </div>
                    <div class="right-content" style="display: flex;align-items: center;margin-bottom:4px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        Invoice Date<span style="float: right;">:</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        {{invoiceDate | date:"dd MMM YYYY" | uppercase }}
                      </div>
                    </div>
                    <!-- <div class="right-content" style="display: flex;align-items: center;margin-bottom:4px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        Due Date<span style="float: right;">:</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        {{ dueDate ? (dueDate | date:'dd MMM YYYY' | uppercase) : 'NA' }}
                      </div>
                    </div> -->
                  </div>
                </div>

                <div class="flex-row" style="display: flex;margin: 8px 0px;">
                  <div class="row-left" style="display: flex;flex-direction: column;flex-basis: 50%;">
                    <span class="row-2-heading"
                      style="width: 80px;font-weight: 600;background-color: #ccd4dd;-webkit-print-color-adjust: exact;margin:8px 0px;padding:8px;"
                      [style.background]="selectedColor">BILL TO</span>
                    <span style="font-weight: 600;margin-bottom:4px;">{{salesData.customerName ? salesData.customerName
                      : "NA"}}</span>
                    <span style="margin-bottom: 4px;">{{ billingAddress ? billingAddress : "NA"}}</span>
                    <span style="margin-bottom: 4px;">Contact # : &nbsp;{{ salesData.mobile ? salesData.mobile : "NA"
                      }}</span>
                    <span style="margin-bottom: 4px;">GSTIN: &nbsp;{{gstinNo ? gstinNo : "NA"}}</span>
                  </div>
                  <div class="row-right" style="display: flex;flex-direction: column;flex-basis: 50%;">
                    <span class="row-2-heading"
                      style="width: 90px;font-weight: 600;background-color: #ccd4dd;-webkit-print-color-adjust: exact;margin:8px 0px;padding:8px;"
                      [style.background]="selectedColor">SHIP TO</span>
                    <span style="font-weight: 600;margin-bottom:4px;">{{salesData.customerName ? salesData.customerName
                      : "NA"}}</span>
                    <span style="margin-bottom: 4px;">{{ shippingAddress ? shippingAddress : "NA"}}</span>
                  </div>
                </div>

                <div class="row table-responsive scrollbar fs--1"
                  style="padding: 0px 10px;margin-top:16px;  min-height: fit-content;height: auto !important;">
                  <table class="table tablePrint" style="font-size: 14px;">
                    <thead class="inner-model-table-heading printHead"
                      style="font-size: 14px;background-color: #ccd4dd;-webkit-print-color-adjust: exact;padding-top: 20px !important;padding-bottom: 20px !important;font-size: 14px !important;">
                      <tr class="text-white" [style.background]="selectedColor">
                        <th style="text-align: left;font-size: 14px;padding: 10px;" [style.background]="selectedColor">#
                        </th>
                        <th style="text-align: left;width:20rem;font-size: 14px;padding: 10px;"
                          [style.background]="selectedColor">ITEMS</th>
                        <th style="text-align: right;font-size: 14px;padding: 10px;" [style.background]="selectedColor">
                          QTY.</th>
                        <th style="text-align: right;font-size: 14px;padding: 10px;" [style.background]="selectedColor">
                          RATE(Excluding Tax)</th>
                        <th style="text-align: right;font-size: 14px;padding: 10px;" [style.background]="selectedColor">
                          <!-- TAX -->
                        </th>
                        <th style="text-align: right;font-size: 14px;padding: 10px;" [style.background]="selectedColor">
                          AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody class="printBody"
                      style="max-height: 200px;font-size: 14px;padding-top: 20px !important;padding-bottom: 20px !important;font-size: 14px !important;">
                      <tr *ngFor="let i of saleProductData; let index = index">
                        <td style="text-align: left;font-size: 14px;padding:10px;">{{ index + 1 }}</td>
                        <td style="word-wrap: break-word;max-width: 150px;white-space: wrap;padding:10px;">
                          <span style="text-transform: uppercase;font-size: 14px;">{{i?.itemName}}</span>
                        </td>
                        <td style="text-align: right;white-space: nowrap;font-size: 14px;padding:10px;">
                          {{i?.quantity}} {{i?.unit?.substr(i?.unit?.length - 5)}}</td>
                        <td style="text-align: right;font-size: 14px;padding:10px;">
                          ₹&nbsp;{{ (i?.pricePerUnit / (1 + (i?.gst/100))) | number:'1.2-2'}}
                          <!-- ₹&nbsp;{{ (i.quantity * (i.pricePerUnit / (1 + (i.gst/100)))) | number:'1.2-2'}} -->
                        </td>
                        <td style="padding:10px;">
                          <!-- <span
                            style="display: flex;flex-direction:column;align-items:flex-end;font-size: 14px;">
                            <span>₹&nbsp;{{ (i.gstAmount/2 + i.gstAmount/2) | number:'1.2-2'}}({{ i.gst}}%)</span>
                         </span> -->
                        </td>
                        <td style="text-align: right;font-size: 14px;padding:10px;">
                          <!-- ₹&nbsp;{{ i.finalAmount | number:'1.2-2'}} -->
                          ₹&nbsp;{{ (i?.quantity * (i?.pricePerUnit / (1 + (i.gst/100)))) | number:'1.2-2'}}
                        </td>
                      </tr>
                      <!-- <tr *ngIf="(flatDiscount !== null) && (flatDiscount > 0)">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style="text-align: right;font-size: 14px;padding:10px;">Flat Discount</td>
                        <td></td>
                        <td style="text-align: right;font-size: 14px;padding:10px;">- ₹&nbsp;{{ (flatDiscount || 0) |
                          number:'1.2-2' }}</td>
                      </tr> -->
                    </tbody>
                    <tfoot class="inner-model-table-heading printFoot"
                      style="font-size: 14px;background-color: #ccd4dd;-webkit-print-color-adjust: exact;padding-top: 20px !important;padding-bottom: 20px !important;">
                      <tr [style.background]="selectedColor">
                        <td style="padding:10px;"></td>
                        <th style="text-align: left;font-size: 14px;padding:10px;">SUBTOTAL</th>
                        <td style="padding:10px;"></td>
                        <td style="padding:10px;"></td>
                        <th style="text-align: right;font-weight: 600;font-size: 14px;padding:10px;">
                          <!--₹&nbsp;{{salesData?.billGstAmount | number:'1.2-2'}} -->
                        </th>
                        <th style="text-align: right;font-weight: 600;font-size: 14px;padding:10px;">
                          ₹&nbsp;{{ salesData?.totalBillAmount === 0 ? 0.00: ((salesData?.totalBillAmount -
                          (salesData?.billGstAmount)) | number:'1.2-2') }}
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>

                <div class="flex-row" style="display: flex;margin: 8px 0px;">
                  <div class="row-left"
                    style="display: flex;flex-direction: column;flex-basis: 50%;justify-content: center;">
                  </div>
                  <div class="row-right" style="display: flex;flex-direction: column;flex-basis: 50%;">
                    <div class="right-content" style="display: flex;align-items: center;margin-top: 8px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        <span style="font-weight: 600;">TAXABLE AMOUNT</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        ₹&nbsp;{{totalBillAmount - billGstAmount | number:'1.2-2'}}
                      </div>
                    </div>
                    <div class="right-content" style="display: flex;align-items: center;margin-top: 4px;">
                      <!-- *ngIf="gstStateMatch === true" -->
                      <div class="content-length" style="flex-basis: 50%;">
                        <span> {{lang?.CGST}}</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        ₹&nbsp;{{salesData?.billGstAmount | number:'1.2-2'}}
                      </div>
                    </div>

                    <div *ngIf="salesData?.couponDiscountAmount !=0" class="right-content"
                      style="display: flex;align-items: center;margin-top: 4px;">
                      <!-- *ngIf="gstStateMatch === true" -->
                      <div class="content-length" style="flex-basis: 50%;">
                        <span> Coupon Discount </span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        -₹&nbsp;{{salesData?.couponDiscountAmount | number:'1.2-2'}}
                      </div>
                    </div>

                    <div *ngIf="salesData?.flatDiscount !=0" class="right-content"
                      style="display: flex;align-items: center;margin-top: 4px;">
                      <!-- *ngIf="gstStateMatch === true" -->
                      <div class="content-length" style="flex-basis: 50%;">
                        <span> Flat Discount</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        -₹&nbsp;{{salesData?.flatDiscount | number:'1.2-2'}}
                      </div>
                    </div>

                    <div *ngIf="salesData?.counterDiscountAmount !=0" class="right-content"
                      style="display: flex;align-items: center;margin-top: 4px;">
                      <!-- *ngIf="gstStateMatch === true" -->
                      <div class="content-length" style="flex-basis: 50%;">
                        <span> Counter Discount</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        -₹&nbsp;{{salesData?.counterDiscountAmount | number:'1.2-2'}}
                      </div>
                    </div>

                    <div *ngIf="salesData?.giftcardCode" class="right-content"
                      style="display: flex;align-items: center;margin-top: 4px;">
                      <!-- *ngIf="gstStateMatch === true" -->
                      <div class="content-length" style="flex-basis: 50%;">
                        <span> Gift Card: {{ salesData?.giftcardCode }}</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        -₹&nbsp;{{ (salesData?.masterFinalGiftcardPrice || 0) | number:'1.2-2' }}
                      </div>
                    </div>

                    <!-- <div class="right-content" *ngIf="(flatDiscount !== null) && (flatDiscount > 0)"
                      style="display: flex;align-items: center;margin-top: 4px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        <span> Flat Discount</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        ₹&nbsp;{{ (flatDiscount || 0) | number:'1.2-2'}}
                      </div>
                    </div> -->
                    <hr [style.border]="selectedColor ? '1px solid ' + selectedColor : 'none'"
                      style="-webkit-print-color-adjust: exact; width: 100%;">
                    <!-- <hr style = "border: 1px solid grey;;-webkit-print-color-adjust: exact;width:100%;" /> -->
                    <div class="right-content" style="display: flex;align-items: center;margin-top: 4px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        <span style="font-weight: 600;">TOTAL AMOUNT</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        ₹&nbsp;{{ (salesData?.masterFinalPrice || 0 ) | number:'1.2-2'}}
                      </div>
                    </div>
                    <hr [style.border]="selectedColor ? '1px solid ' + selectedColor : 'none'"
                      style="-webkit-print-color-adjust: exact; width: 100%;">
                    <!-- <hr style = "border: 1px solid grey;;-webkit-print-color-adjust: exact;width:100%;" /> -->
                    <div class="right-content" style="display: flex;align-items: center;margin-top: 4px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        <span>Received Amount</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        ₹&nbsp;{{salesData?.received | number:'1.2-2'}}
                      </div>
                    </div>
                    <div class="right-content" style="display: flex;align-items: center;margin-top: 4px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        <span style="font-weight: 600;">Balance</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        ₹&nbsp;{{salesData?.balance | number:'1.2-2'}}
                      </div>
                    </div>
                    <div class="right-content"
                      style="display: flex;align-items:end;justify-content:end;margin-top: 4px;">
                      <div style="text-align: end;">
                        <p style="margin-bottom: 4px;">Total Amount (in words)</p>
                        <span>{{ finalAmount| titlecase}} Rupees</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6" style="display: flex;flex-direction: column;justify-content: flex-start;">
                    <span class="mb-1 mt-5" style="font-weight: 600;">TERMS AND CONDITIONS</span>
                    <span #dynamicText [style.fontSize.px]="getTextSizeprint(TextSizeprint)"
                      style="margin-bottom: 4px;">{{invoiceTermsCon ? invoiceTermsCon :'NA'}}</span>
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12"
                    style="display: flex;flex-direction: column;justify-content: flex-end;">
                    <div class="image-wrapper">
                      <label class="signature-img" ondragover="return false;">
                        <img *ngIf="signImg" [src]="signImg" />
                      </label>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<!-- Normal Print -->
<div class="modal modal-invoice fade" #closebutton id="invoice-modal2" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content position-relative">
      <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
        <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
          id="closeModalPrint">
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="container" id="normal-print">
          <div class="card" style="box-shadow: none !important;">
            <div class="card-body">

              <div class="flex-container" style="margin-top: 16px;">

                <div class="flex-row" style="display: flex;margin: 8px 0px;">
                  <div class="row-left" style="display: flex;flex-direction: column;flex-basis: 50%;">
                    <span style="font-weight: 600;margin:5px 0px 12px" [style.color]="selectedColor" #dynamicText
                      [style.fontSize.px]="getTextSize(selectedTextSize)">{{tradeName ? tradeName : "NA"}}</span>
                    <span>Contact:&nbsp;{{ mobile ? mobile : 'NA'}}</span>
                  </div>
                  <div class="row-right" style="display: flex;flex-direction:column;flex-basis:50%;">
                    <div class="right-content" style="display: flex;align-items: center;">

                      <div class="content-length" style="flex-basis: 50%;">
                        <span style="font-weight: 600;">TAX INVOICE</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;">
                        <button
                          style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                          id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0" type="button"
                          (click)="onPrint()"><span class="fas fa-print me-1">
                          </span>Print
                        </button>
                        <div id="originalForReceiptPrint">
                          ORIGINAL FOR RECIPIENT
                        </div>
                      </div>
                    </div>
                    <div class="right-content" style="display: flex;align-items: center;margin-bottom:4px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        Invoice #<span style="float: right;">:</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        {{invoiceNo}}
                      </div>
                    </div>
                    <div class="right-content" style="display: flex;align-items: center;margin-bottom:4px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        Invoice Date<span style="float: right;">:</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        {{invoiceDate | date:"dd MMM YYYY" | uppercase }}
                      </div>
                    </div>
                    <!-- <div class="right-content" style="display: flex;align-items: center;margin-bottom:4px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        Due Date<span style="float: right;">:</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        {{ dueDate ? (dueDate | date:'dd MMM YYYY' | uppercase) : 'NA' }}
                      </div>
                    </div> -->
                  </div>
                </div>

                <div class="flex-row" style="display: flex;margin: 8px 0px;">
                  <div class="row-left" style="display: flex;flex-direction: column;flex-basis: 50%;">
                    <span class="row-2-heading"
                      style="width: 80px;font-weight: 600;background-color: #ccd4dd;-webkit-print-color-adjust: exact;margin:8px 0px;padding:8px;"
                      [style.background]="selectedColor">BILL TO</span>
                    <span style="font-weight: 600;margin-bottom:4px;">{{salesData?.customerName ?
                      salesData?.customerName : "NA"}}</span>
                    <span style="margin-bottom: 4px;">{{ billingAddress ? billingAddress : "NA"}}</span>
                    <span style="margin-bottom: 4px;">Contact # : &nbsp;{{ salesData?.mobile ? salesData?.mobile : "NA"
                      }}</span>
                    <span style="margin-bottom: 4px;">{{lang?.GSTIN}}: &nbsp;{{gstinNo ? gstinNo : "NA"}}</span>
                  </div>
                  <div class="row-right" style="display: flex;flex-direction: column;flex-basis: 50%;">
                    <span class="row-2-heading"
                      style="width: 90px;font-weight: 600;background-color: #ccd4dd;-webkit-print-color-adjust: exact;margin:8px 0px;padding:8px;"
                      [style.background]="selectedColor">SHIP TO</span>
                    <span style="font-weight: 600;margin-bottom:4px;">{{salesData?.customerName ?
                      salesData?.customerName
                      : "NA"}}</span>
                    <span style="margin-bottom: 4px;">{{ shippingAddress ? shippingAddress : "NA"}}</span>
                  </div>
                </div>

                <div class="row table-responsive scrollbar fs--1">
                  <table class="table tablePrint" style="font-size: 14px;">
                    <thead class="inner-model-table-heading printHead"
                      style="font-size: 14px;background-color: #ccd4dd;-webkit-print-color-adjust: exact;padding-top: 20px !important;padding-bottom: 20px !important;font-size: 14px !important;">
                      <tr class="text-white" [style.background]="selectedColor">
                        <th style="text-align: left;font-size: 14px;padding: 10px;" [style.background]="selectedColor">#
                        </th>
                        <th style="text-align: left;width:20rem;font-size: 14px;padding: 10px;"
                          [style.background]="selectedColor">ITEMS</th>
                        <th style="text-align: right;font-size: 14px;padding: 10px;" [style.background]="selectedColor">
                          QTY.</th>
                        <th style="text-align: right;font-size: 14px;padding: 10px;" [style.background]="selectedColor">
                          RATE</th>
                        <!-- <th style="text-align: right;font-size: 14px;padding: 10px;" [style.background]="selectedColor">
                          TAX
                        </th> -->
                        <th style="text-align: right;font-size: 14px;padding: 10px;" [style.background]="selectedColor">
                          AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody class="printBody">
                      <tr *ngFor="let i of saleProductData; let index = index">
                        <td style="text-align: left;font-size: 14px;padding:10px;">{{ index+1 }}</td>
                        <td style="word-wrap: break-word;max-width: 150px;white-space: wrap;padding:10px;">
                          <span style="text-transform: uppercase;font-size: 14px;">{{i?.itemName}}</span>
                        </td>
                        <td style="text-align: right;white-space: nowrap;font-size: 14px;padding:10px;">
                          {{i?.quantity}} {{i.unit?.substr(i?.unit.length - 5)}}</td>
                        <td style="text-align: right;font-size: 14px;padding:10px;">
                          ₹&nbsp;{{ ((i?.pricePerUnit / (1 + (i?.gst/100)))) | number:'1.2-2'}}
                          <!-- ₹&nbsp;{{ (i.quantity * (i.pricePerUnit / (1 + (i.gst/100)))) | number:'1.2-2'}} -->
                        </td>
                        <!-- <td style="padding:10px;">
                          <span
                            style="display: flex;flex-direction:column;align-items:flex-end;font-size: 14px;">
                            <span>₹&nbsp;{{ (i.gstAmount/2 + i.gstAmount/2) | number:'1.2-2'}}</span>
                            <span style="font-size: 12px;">({{ i.gst}}%)</span>
                          </span>
                        </td> -->
                        <td style="text-align: right;font-size: 14px;padding:10px;">
                          <!-- ₹&nbsp;{{ i?.finalAmount | number:'1.2-2'}} -->
                          ₹&nbsp;{{ (i?.quantity * (i?.pricePerUnit / (1 + (i?.gst/100)))) | number:'1.2-2'}}
                        </td>
                      </tr>
                      <!-- <tr *ngIf="(flatDiscount !== null) && (flatDiscount > 0)">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style="text-align: right;font-size: 14px;padding:10px;">Flat Discount</td>
                        <td></td>
                        <td style="text-align: right;font-size: 14px;padding:10px;">- ₹&nbsp;{{ (flatDiscount || 0) |
                          number:'1.2-2' }}</td>
                      </tr> -->
                    </tbody>
                    <tfoot class="inner-model-table-heading printFoot"
                      style="font-size: 14px;background-color: #ccd4dd;-webkit-print-color-adjust: exact;padding-top: 20px !important;padding-bottom: 20px !important;font-size: 14px !important;">
                      <tr [style.background]="selectedColor">
                        <td style="padding:10px;"></td>
                        <th style="text-align: left;font-size: 14px;padding:10px;">SUBTOTAL</th>
                        <td style="padding:10px;"></td>
                        <td style="padding:10px;"></td>
                        <!-- <th style="text-align: right;font-weight: 600;font-size: 14px;padding:10px;">
                          ₹&nbsp;{{salesData.billGstAmount | number:'1.2-2'}}
                        </th> -->
                        <th style="text-align: right;font-weight: 600;font-size: 14px;padding:10px;">
                          ₹&nbsp;{{ salesData?.totalBillAmount === 0 ? 0.00: ((salesData?.totalBillAmount -
                          (salesData?.billGstAmount)) | number:'1.2-2') }}
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>

                <div class="flex-row" style="display: flex;margin: 8px 0px;">
                  <div class="row-left"
                    style="display: flex;flex-direction: column;flex-basis: 50%;justify-content: center;">
                  </div>
                  <div class="row-right" style="display: flex;flex-direction: column;flex-basis: 50%;">
                    <div class="right-content" style="display: flex;align-items: center;margin-top: 8px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        <span style="font-weight: 600;">TAXABLE AMOUNT</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        ₹&nbsp;{{totalBillAmount - billGstAmount | number:'1.2-2'}}
                      </div>
                    </div>
                    <div class="right-content" style="display: flex;align-items: center;margin-top: 4px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        <span> {{lang?.CGST}}</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        ₹&nbsp;{{salesData?.billGstAmount | number:'1.2-2'}}
                      </div>
                    </div>
                    <div *ngIf="salesData?.couponDiscountAmount !=0" class="right-content"
                      style="display: flex;align-items: center;margin-top: 4px;">
                      <!-- *ngIf="gstStateMatch === true" -->
                      <div class="content-length" style="flex-basis: 50%;">
                        <span> Coupon Discount </span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        -₹&nbsp;{{salesData?.couponDiscountAmount | number:'1.2-2'}}
                      </div>
                    </div>

                    <div *ngIf="salesData?.flatDiscount !=0" class="right-content"
                      style="display: flex;align-items: center;margin-top: 4px;">
                      <!-- *ngIf="gstStateMatch === true" -->
                      <div class="content-length" style="flex-basis: 50%;">
                        <span> Flat Discount</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        -₹&nbsp;{{salesData?.flatDiscount | number:'1.2-2'}}
                      </div>
                    </div>

                    <div *ngIf="salesData?.counterDiscountAmount !=0" class="right-content"
                      style="display: flex;align-items: center;margin-top: 4px;">
                      <!-- *ngIf="gstStateMatch === true" -->
                      <div class="content-length" style="flex-basis: 50%;">
                        <span> Counter Discount</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        -₹&nbsp;{{salesData?.counterDiscountAmount | number:'1.2-2'}}
                      </div>
                    </div>

                    <div *ngIf="salesData?.giftcardCode" class="right-content"
                      style="display: flex;align-items: center;margin-top: 4px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        <span> Gift Card: {{ salesData?.giftcardCode }}</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        -₹&nbsp;{{ (salesData?.masterFinalGiftcardPrice || 0) | number:'1.2-2' }}
                      </div>
                    </div>
                    <!-- <div class="right-content" *ngIf="(flatDiscount !== null) && (flatDiscount > 0)"
                      style="display: flex;align-items: center;margin-top: 4px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        <span> Flat Discount</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        ₹&nbsp;{{ (flatDiscount || 0) | number:'1.2-2'}}
                      </div>
                    </div> -->
                    <hr [style.border]="selectedColor ? '1px solid ' + selectedColor : 'none'"
                      style="-webkit-print-color-adjust: exact; width: 100%;">
                    <!-- <hr style = "border: 1px solid grey;;-webkit-print-color-adjust: exact;width:100%;" /> -->
                    <div class="right-content" style="display: flex;align-items: center;margin-top: 4px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        <span style="font-weight: 600;">TOTAL AMOUNT</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        ₹&nbsp;{{ (salesData?.masterFinalPrice || 0 ) | number:'1.2-2'}}
                      </div>
                    </div>
                    <hr [style.border]="selectedColor ? '1px solid ' + selectedColor : 'none'"
                      style="-webkit-print-color-adjust: exact; width: 100%;">
                    <!-- <hr style = "border: 1px solid grey;;-webkit-print-color-adjust: exact;width:100%;" /> -->
                    <div class="right-content" style="display: flex;align-items: center;margin-top: 4px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        <span>Received Amount</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        ₹&nbsp;{{salesData?.received | number:'1.2-2'}}
                      </div>
                    </div>
                    <div class="right-content" style="display: flex;align-items: center;margin-top: 4px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        <span style="font-weight: 600;">Balance</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        ₹&nbsp;{{salesData?.balance | number:'1.2-2'}}
                      </div>
                    </div>
                    <div class="right-content"
                      style="display: flex;align-items:end;justify-content:end;margin-top: 4px;">
                      <div style="text-align: end;">
                        <p style="margin-bottom: 4px;">Total Amount (in words)</p>
                        <span>{{ finalAmount| titlecase}} Rupees</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div style="display: flex;margin: 8px 0px;">
                  <div style="display: flex;flex-direction: column;flex-basis: 50%;">
                    <span class="mb-1 mt-5" style="font-weight: 600;">TERMS AND CONDITIONS</span>
                    <span #dynamicText [style.fontSize.px]="getTextSizeprint(TextSizeprint)"
                      style="margin-bottom: 4px;">{{invoiceTermsCon ? invoiceTermsCon :'NA'}}</span>
                  </div>
                  <div style="display: flex;flex-direction: column;flex-basis: 50%;">
                    <div class="image-wrapper">
                      <label class="signature-img" ondragover="return false;">
                        <img style="width:200px;height:150px;" *ngIf="signImg" [src]="signImg" />
                      </label>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<!-- Thermal Print -->
<div class="modal modal-invoice fade" #closebutton id="invoice-modal2" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content position-relative">
      <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
        <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
          id="closeModalPrint">
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="container" id="thermal-panel">
          <div class="card" style="box-shadow: none !important;">
            <div class="card-body">

              <div class="flex-container" style="margin-top: 16px;">

                <div class="flex-row" style="display: flex;flex-direction: column;margin: 8px 0px;">
                  <div class="row-left" style="display: flex;flex-direction: column;flex-basis: 50%;">
                    <span style="font-weight: 600;margin:5px 0px 12px" [style.color]="selectedColor" #dynamicText
                      [style.fontSize.px]="getTextSize(selectedTextSize)">{{tradeName ? tradeName : "NA"}}</span>
                    <span>Contact:&nbsp;{{ mobile ? mobile : 'NA'}}</span>
                  </div>
                  <div class="row-right" style="display: flex;flex-direction:column;flex-basis:50%;margin-top: 16px;">
                    <div class="right-content"
                      style="display: flex;flex-direction: column;align-items: flex-start;margin:4px 0;">
                      <!-- <div class="content-length" style="flex-basis: 50%;">
                        <span style="font-weight: 600;">AX INVOICE</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;">
                        <button
                          style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                          id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0" type="button"
                          (click)="onPrint()"><span class="fas fa-print me-1">
                          </span>Print
                        </button>
                        <div id="originalForReceiptPrint">
                          ORIGINAL FOR RECIPIENT
                        </div>
                      </div> -->
                      <div class="content-length" style="flex-basis: 30%;">
                        <span style="font-weight: 600;">TAX INVOICE</span>
                      </div>
                      <div class="content-length" style="flex-basis: 40%;">
                        <button
                          style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                          id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0" type="button"
                          (click)="thermalPrint()"><span class="fas fa-print me-1">
                          </span>Thermal Print
                        </button>
                      </div>
                      <div class="content-length" style="flex-basis: 30%;">
                        <button
                          style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                          id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0" type="button"
                          (click)="onPrint()"><span class="fas fa-print me-1">
                          </span>Print
                        </button>
                        <div id="originalForReceiptPrint" style="margin: 4px 0;">
                          ORIGINAL FOR RECIPIENT
                        </div>
                      </div>
                    </div>
                    <div class="right-content"
                      style="display: flex;flex-direction: column;align-items: flex-start;margin:4px 0;">
                      <div class="content-length">
                        Invoice #<span style="float: right;">:</span>
                      </div>
                      <div class="content-length" style="margin: 4px 0;">
                        {{invoiceNo}}
                      </div>
                    </div>
                    <div class="right-content"
                      style="display: flex;flex-direction: column;align-items: flex-start;margin:4px 0;">
                      <div class="content-length">
                        Invoice Date<span style="float: right;">:</span>
                      </div>
                      <div class="content-length" style="margin: 4px 0;">
                        {{invoiceDate | date:"dd MMM YYYY" | uppercase }}
                      </div>
                    </div>
                    <!-- <div class="right-content"
                      style="display: flex;flex-direction: column;align-items: flex-start;margin:4px 0;">
                      <div class="content-length">
                        Due Date<span style="float: right;">:</span>
                      </div>
                      <div class="content-length" style="margin: 4px 0;">
                        {{ dueDate ? (dueDate | date:'dd MMM YYYY' | uppercase) : 'NA' }}
                      </div>
                    </div> -->
                  </div>
                </div>

                <div class="flex-row" style="display: flex;flex-direction: column;margin: 8px 0px;">
                  <div class="row-left" style="display: flex;flex-direction: column;flex-basis: 50%;">
                    <span class="row-2-heading"
                      style="width: 80px;font-weight: 600;background-color: #ccd4dd;-webkit-print-color-adjust: exact;margin:8px 0px;padding:8px;"
                      [style.background]="selectedColor">BILL TO</span>
                    <span style="font-weight: 600;margin-bottom:4px;">{{salesData?.customerName ?
                      salesData?.customerName
                      : "NA"}}</span>
                    <span style="margin-bottom: 4px;">{{ billingAddress ? billingAddress : "NA"}}</span>
                    <span style="margin-bottom: 4px;">Contact: &nbsp;{{ salesData?.mobile ? salesData?.mobile : "NA"
                      }}</span>
                    <span style="margin-bottom: 4px;">{{lang?.GSTIN}}: &nbsp;{{gstinNo ? gstinNo : "NA"}}</span>
                  </div>
                  <div class="row-right" style="display: flex;flex-direction: column;flex-basis: 50%;margin-top:16px;">
                    <span class="row-2-heading"
                      style="width: 90px;font-weight: 600;background-color: #ccd4dd;-webkit-print-color-adjust: exact;margin:8px 0px;padding:8px;"
                      [style.background]="selectedColor">SHIP TO</span>
                    <span style="font-weight: 600;margin-bottom:4px;">{{salesData?.customerName ?
                      salesData?.customerName
                      : "NA"}}</span>
                    <span style="margin-bottom: 4px;">{{ shippingAddress ? shippingAddress : "NA"}}</span>
                  </div>
                </div>

                <div class="row table-responsive scrollbar fs--1"
                  style="padding: 0px;margin-top:16px;min-height: fit-content;height: auto !important;width:400px;">
                  <table class="table tablePrint" style="font-size: 14px;">
                    <thead class="inner-model-table-heading printHead"
                      style="font-size: 14px;background-color: #ccd4dd;-webkit-print-color-adjust: exact;padding-top: 20px !important;padding-bottom: 20px !important;font-size: 14px !important;">
                      <tr class="text-white" [style.background]="selectedColor">
                        <th style="text-align: left;width:20rem;font-size: 14px;padding: 10px;"
                          [style.background]="selectedColor">ITEMS</th>
                        <th style="text-align: right;font-size: 14px;padding: 10px;" [style.background]="selectedColor">
                          QTY.</th>
                        <th style="text-align: right;font-size: 14px;padding: 10px;" [style.background]="selectedColor">
                          RATE</th>
                        <th style="text-align: right;font-size: 14px;padding: 10px;" [style.background]="selectedColor">
                          AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody class="printBody"
                      style="max-height: 200px;font-size: 14px;padding-top: 20px !important;padding-bottom: 20px !important;font-size: 14px !important;">
                      <tr *ngFor="let i of saleProductData; let index = index">
                        <td style="word-wrap: break-word;max-width: 20px;white-space: wrap;padding:10px;">
                          <span style="text-transform: uppercase;font-size: 14px;">{{i?.itemName}}</span>
                        </td>
                        <td style="text-align: right;white-space: nowrap;font-size: 14px;padding:10px;">
                          {{i?.quantity}}
                        </td>
                        <td style="text-align: right;font-size: 14px;padding:10px;">
                          ₹&nbsp;{{ ((i?.pricePerUnit / (1 + (i?.gst/100)))) | number:'1.2-2'}}
                          <!-- ₹&nbsp;{{ (i.quantity * (i.pricePerUnit / (1 + (i.gst/100)))) | number:'1.2-2'}} -->
                        </td>
                        <td style="padding:10px;">
                          <span style="display: flex;flex-direction:column;align-items:flex-end;font-size: 14px;">
                            <span> ₹&nbsp;{{ (i.quantity * (i.pricePerUnit / (1 + (i.gst/100)))) |
                              number:'1.2-2'}}</span>
                            <!-- <span> ₹&nbsp;{{ i?.finalAmount | number:'1.2-2'}}</span> -->
                            <span style="font-size: 12px;">({{ i?.gst}}%)</span>
                          </span>
                        </td>
                      </tr>
                      <!-- <tr *ngIf="(flatDiscount !== null) && (flatDiscount > 0)">
                        <td></td>
                        <td style="text-align: right;font-size: 14px;padding:10px;">Flat Discount</td>
                        <td></td>
                        <td style="text-align: right;font-size: 14px;padding:10px;">- ₹&nbsp;{{ (flatDiscount || 0) |
                          number:'1.2-2' }}</td>
                      </tr> -->
                    </tbody>
                    <tfoot class="inner-model-table-heading printFoot"
                      style="font-size: 14px;background-color: #ccd4dd;-webkit-print-color-adjust: exact;padding-top: 20px !important;padding-bottom: 20px !important;font-size: 14px !important;">
                      <tr [style.background]="selectedColor">
                        <th style="text-align: left;font-size: 14px;padding:10px;">SUBTOTAL</th>
                        <td style="padding:10px;"></td>
                        <td style="padding:10px;"></td>
                        <th style="text-align: right;font-weight: 600;font-size: 14px;padding:10px;">
                          ₹&nbsp;{{ salesData?.totalBillAmount === 0 ? 0.00: ((salesData?.totalBillAmount -
                          (salesData?.billGstAmount)) | number:'1.2-2') }}
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>

                <div class="flex-row" style="display: flex;margin: 8px 0px;">
                  <div class="row-right" style="display: flex;flex-direction: column;flex-basis:70%;">
                    <div class="right-content" style="display: flex;align-items: center;margin-top: 8px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        <span style="font-weight: 600;">TAXABLE AMOUNT</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        ₹&nbsp;{{ (totalBillAmount - billGstAmount)| number:'1.2-2'}}
                      </div>
                    </div>
                    <div class="right-content" style="display: flex;align-items: center;margin-top: 4px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        <span> {{lang?.CGST}}</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        ₹&nbsp;{{ salesData?.billGstAmount | number:'1.2-2'}}
                      </div>
                    </div>
                    <div *ngIf="salesData?.couponDiscountAmount !=0" class="right-content"
                      style="display: flex;align-items: center;margin-top: 4px;">
                      <!-- *ngIf="gstStateMatch === true" -->
                      <div class="content-length" style="flex-basis: 50%;">
                        <span> Coupon Discount </span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        -₹&nbsp;{{salesData?.couponDiscountAmount | number:'1.2-2'}}
                      </div>
                    </div>

                    <div *ngIf="salesData?.flatDiscount !=0" class="right-content"
                      style="display: flex;align-items: center;margin-top: 4px;">
                      <!-- *ngIf="gstStateMatch === true" -->
                      <div class="content-length" style="flex-basis: 50%;">
                        <span> Flat Discount</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        -₹&nbsp;{{salesData?.flatDiscount | number:'1.2-2'}}
                      </div>
                    </div>

                    <div *ngIf="salesData?.counterDiscountAmount !=0" class="right-content"
                      style="display: flex;align-items: center;margin-top: 4px;">
                      <!-- *ngIf="gstStateMatch === true" -->
                      <div class="content-length" style="flex-basis: 50%;">
                        <span> Counter Discount</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        -₹&nbsp;{{salesData?.counterDiscountAmount | number:'1.2-2'}}
                      </div>
                    </div>
                    <div *ngIf="salesData?.couponDiscountAmount !=0" class="right-content"
                      style="display: flex;align-items: center;margin-top: 4px;">
                      <!-- *ngIf="gstStateMatch === true" -->
                      <div class="content-length" style="flex-basis: 50%;">
                        <span> Coupon Discount </span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        -₹&nbsp;{{salesData?.couponDiscountAmount | number:'1.2-2'}}
                      </div>
                    </div>

                    <div *ngIf="salesData?.flatDiscount !=0" class="right-content"
                      style="display: flex;align-items: center;margin-top: 4px;">
                      <!-- *ngIf="gstStateMatch === true" -->
                      <div class="content-length" style="flex-basis: 50%;">
                        <span> Flat Discount</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        -₹&nbsp;{{salesData?.flatDiscount | number:'1.2-2'}}
                      </div>
                    </div>

                    <div *ngIf="salesData?.counterDiscountAmount !=0" class="right-content"
                      style="display: flex;align-items: center;margin-top: 4px;">
                      <!-- *ngIf="gstStateMatch === true" -->
                      <div class="content-length" style="flex-basis: 50%;">
                        <span> Counter Discount</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        -₹&nbsp;{{salesData?.counterDiscountAmount | number:'1.2-2'}}
                      </div>
                    </div>

                    <div *ngIf="salesData?.giftcardCode" class="right-content"
                      style="display: flex;align-items: center;margin-top: 4px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        <span> Gift Card: {{ salesData?.giftcardCode }}</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        -₹&nbsp;{{ (salesData?.masterFinalGiftcardPrice || 0) | number:'1.2-2' }}
                      </div>
                    </div>
                    <!-- <div class="right-content" *ngIf="(flatDiscount !== null) && (flatDiscount > 0)"
                      style="display: flex;align-items: center;margin-top: 4px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        <span> Flat Discount</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        ₹&nbsp;{{ (flatDiscount || 0) | number:'1.2-2'}}
                      </div>
                    </div> -->
                    <hr [style.border]="selectedColor ? '1px solid ' + selectedColor : 'none'"
                      style="-webkit-print-color-adjust: exact; width: 100%;">
                    <!-- <hr style = "border: 1px solid grey;;-webkit-print-color-adjust: exact;width:100%;" /> -->
                    <div class="right-content" style="display: flex;align-items: center;margin-top: 4px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        <span style="font-weight: 600;">TOTAL AMOUNT</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        ₹&nbsp;{{ (salesData?.masterFinalPrice || 0 ) | number:'1.2-2'}}
                      </div>
                    </div>
                    <hr [style.border]="selectedColor ? '1px solid ' + selectedColor : 'none'"
                      style="-webkit-print-color-adjust: exact; width: 100%;">
                    <!-- <hr style = "border: 1px solid grey;;-webkit-print-color-adjust: exact;width:100%;" /> -->
                    <div class="right-content" style="display: flex;align-items: center;margin-top: 4px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        <span>Received Amount</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        ₹&nbsp;{{salesData?.received | number:'1.2-2'}}
                      </div>
                    </div>
                    <div class="right-content" style="display: flex;align-items: center;margin-top: 4px;">
                      <div class="content-length" style="flex-basis: 50%;">
                        <span style="font-weight: 600;">Balance</span>
                      </div>
                      <div class="content-length" style="flex-basis: 50%;text-align: end;">
                        ₹&nbsp;{{salesData?.balance | number:'1.2-2'}}
                      </div>
                    </div>
                    <div class="right-content"
                      style="display: flex;align-items:end;justify-content:end;margin-top: 4px;">
                      <div style="text-align: end;">
                        <p style="margin-bottom: 4px;">Total Amount (in words)</p>
                        <span>{{ finalAmount| titlecase}} Rupees</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex-row" style="display: flex;flex-direction: column;margin: 16px 0px;flex-basis: 40%;">
                  <div style="display: flex;flex-direction: column;justify-content: flex-start;margin: 8px 0px;">
                    <span class="mb-1 mt-5" style="font-weight: 600;">TERMS AND CONDITIONS</span>
                    <span #dynamicText [style.fontSize.px]="getTextSizeprint(TextSizeprint)"
                      style="margin-bottom: 4px;word-wrap: break-word;max-width:300px;white-space: wrap;">{{invoiceTermsCon
                      ? invoiceTermsCon :'NA'}}</span>
                  </div>
                  <div style="margin: 8px 0px;">
                    <div>
                      <label ondragover="return false;">
                        <img style="width:120px;height:80px;" *ngIf="signImg" [src]="signImg" />
                      </label>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>