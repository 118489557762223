<div class="widget">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Low Stocks Products</mat-card-title>
      <div class="">
        <h5 class="recentContact-Sales">Left Stock</h5>
      </div>
    </mat-card-header>

    <mat-card-content>
      <!-- <div class="row" *ngFor="let item of lowStockProductList | slice : 0 : 5">
        <div class="col-sm-6 col-md-6 p-2 ps-4 text-left">
          <h3 class="card-title partyName">
            {{ item.itemName }}
          </h3>
        </div>
        <div class="col-sm-6 col-md-6 p-2 pe-3 text-right">
          <span class="green-digit p-1">
            <progress
              value="{{ item.stock }}"
              ngbTooltip="{{ item.stock }} stocks are remaining out of {{
                item.openingStock
              }}"
              max="{{ item.openingStock }}"
            ></progress
            >&nbsp;
            <span class="sales-percentage p-2">{{ item.stock }}</span>
          </span>
        </div>
      </div>
      <div
        *ngIf="lowStockProductList.length === 0"
        class="recentParty-notFound"
      >
        <img
          class="image-responsive"
          src="../../../assets/img/icons/spot-illustrations/notfound1.png"
          alt="notfound1"
          width="200"
          height="150"
        />
      </div> -->


      <div class="row" *ngFor="let item of lowStockProductList | slice : 0 : 5">
        <div class="firstRow">
          <div class="leftPart">
            <div class="dashboardName">
              <h3 class="card-title partyName">
                <!-- {{ item?.partyName ? item?.partyName : 'NA' }} -->
                {{ item?.itemName ? item?.itemName : 'NA' }}
              </h3>
            </div>
          </div>
          <div class="finalAmount">
            <span class="green-digit p-1">
              <!-- ₹&nbsp;{{ item.saleAmount | number : "1.2-2" }} -->
              <progress
              value="{{ item.lowStockUnits }}"
              ngbTooltip="{{ item.lowStockUnits }} stocks are remaining out of {{
                item.openingStock
              }}"
              max="{{ item.openingStock }}">
              </progress>
                &nbsp;<span class="sales-percentage p-2">{{ item.lowStockUnits }}</span>
            </span>
          </div>
        </div>
      </div>
      <div *ngIf="lowStockProductList.length === 0" class="recentParty-notFound">
        <img
          class="image-responsive"
          src="../../../assets/img/icons/spot-illustrations/notfound1.png"
          alt="notfound1"
          width="200"
          height="150"
        />
      </div>
    </mat-card-content>
  </mat-card>
</div>
