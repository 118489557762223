import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { PartyService } from 'src/app/core/services/party.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SignupService } from 'src/app/core/services/signup.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { WarehouseService } from 'src/app/core/services/warehouse.service';

@Component({
  selector: 'app-add-warehouse',
  templateUrl: './add-warehouse.component.html',
  styleUrls: ['./add-warehouse.component.scss']
})
export class AddWarehouseComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public warehouseForm: any;
  public submitted: boolean = false;
  public today: Date;
  countryList: any[];
  pinDetails:any;
  city: string[] = [
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jharkhand',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram'
  ];
  hide: boolean = true;
  constructor(private formBuilder: FormBuilder,
    public router: Router, private commonService: CommonService,
    private toastService: ToastNotificationService, public dialog: MatDialog,
    private titleCasePipe: TitleCasePipe,
    public dialogRef: MatDialogRef<AddWarehouseComponent>,
    private warehouseService: WarehouseService,
    public signupService: SignupService,
    private partyService: PartyService,public profileService: ProfileService,
  ) {
    this.today = new Date();
  }

  ngOnInit(): void {
    try {
      this.warehouseForm = this.formBuilder.group({
          name: [null, Validators.required],
          shortName: [null, Validators.required],
          businessName: [null, Validators.required],
          address: [null, Validators.required],
          email: [null, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$")]],
          password: [null, Validators.required],
          country: ["India"],
          pincode: [null],
          city: [null],
          state: [null],
          district: [null],
          mobile: [null, Validators.required],
          contactPerson: [null],
      });
      this.getAllCountries()

    } catch (error) {
        this.toastService.toastMsg({
          title: 'Error',
          content: error
        })
    }
  }

  get f() {
    return this.warehouseForm.controls;
  }

  cancel() {
    this.dialogRef.close();
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  numberDecimalOnly(event): boolean {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onSave() {
    this.submitted = true;
    console.log(this.warehouseForm);
    if (this.warehouseForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    } else {
      const data ={
        "data": {
            "email": this.warehouseForm.value.email,
            "password": this.warehouseForm.value.password,
            "countryId": "102",
            "firstName": this.warehouseForm.value.name,
            "lastName": this.warehouseForm.value.shortName,
            "parentId": this.currentUser.id,
            "businessName":this.warehouseForm.value.businessName,
            "contactPerson":this.warehouseForm.value.contactPerson,
            "contactPersonNo":this.warehouseForm.value.mobile,
        },
        "address": {
            "pincode":this.warehouseForm.value.pincode ? this.warehouseForm.value.pincode: null,
            "address":  this.warehouseForm.value?.address,
            "city": this.warehouseForm.value.city? this.warehouseForm.value.city:null,
            "state": this.warehouseForm.value.state?this.warehouseForm.value.state:null,
            "country": this.warehouseForm.value.country?this.warehouseForm.value.country.toString() : null
        }
    }
    this.signupService.warehouseRegister(data).then( res => {
        if(res.success) {
          this.dialogRef.close();
          this.commonService.notifyDataAdded()
          this.toastService.toastMsg({
            title: "Success",
            content: "Warehouse Added Successfully!",
          });
        }
      },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
      })
    }
  }

  getAllCountries() {
    this.partyService.getAllCountries({}).then((res) => {
      this.countryList = res.data;
    });
  }
  
  StringOnly(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }
    //get pincode api

    getPincodeDetails(pin) {
      this.profileService.getUserPincode({}, pin).then((res) => {
        this.pinDetails = res;
        this.warehouseForm.patchValue({
          district: res.data.districtName,
          state: res.data.stateName,
          city:res.data.districtName,
        });
      });
    }
}
