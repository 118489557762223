import { TitleCasePipe } from '@angular/common';
import lang from "src/assets/langTranslation/language_translation"
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, startWith } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/common.service';
import { PartyService } from 'src/app/core/services/party.service';
import { PaymentInService } from 'src/app/core/services/payment-in.service';
import { PaymentOutService } from 'src/app/core/services/payment-out.service';
import { ProductService } from 'src/app/core/services/product.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
@Component({
  selector: 'app-payment-out',
  templateUrl: './payment-out.component.html',
  styleUrls: ['./payment-out.component.scss']
})
export class PaymentOutComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  @ViewChild('closebutton') closebutton;
  public lang = lang.UAE;
  public PaymentOutForm: FormGroup;
  public gstDetails: any
  public partyId: any;
  public partyData: any
  public dateRange: FormGroup;
  public updated: boolean = false;
  public submitted: boolean = false;
  public date = new Date;
  public allPartyList: any;
  public filteredOptions: any;
  public isShowGstFields: any = false;
  public isShown: boolean = false;
  public todaysDate;
  public isShippingAddressinModal: any;
  public myControl = new FormControl('',[Validators.required]);
  public receiptControl = new FormControl();
  public receiptOptions: any;
  creditInvoices: any;
  public today: Date;
  public maxDate: Date;
  public isDisabledGST: boolean = true;
  public submittedParty: boolean = false;
  public currentPageNo: number = 0;
  public partyPagesize: number = 10;
  public finalObject;
  public paymentId: any;
  public paymentImage: any;
  public imageSrc: any;
  imageLoaded: boolean = false;
  public paramID: any;
  public productPicUrl: string = '';
  PartyForm: FormGroup;

  constructor(private productService: ProductService,
    private formBuilder: FormBuilder, public activeModal: NgbActiveModal, @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PaymentOutComponent>,
    private partyService: PartyService,
    private payemntOutService: PaymentOutService,
    private payemntInService: PaymentInService,
    private router: Router,
    private toastService: ToastNotificationService,
    private saleInvoiceService: SaleInvoiceService,
    private commonService: CommonService,
    private titleCasePipe: TitleCasePipe,
    private modal: NgbModal) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
  }

  ngOnInit(): void {
    this.todaysDate = new Date();
    this.getAllParties();
    this.PaymentOutForm = this.formBuilder.group({
      party: [null],
      ReceiptNo: [null, Validators.required],
      paymentType: [null, Validators.required],
      date: [null, new Date()],
      Description: [null, Validators.required],
      // Files: [""],
      recieved: [{ value: null, disabled: true }],
      total: [null, Validators.required]
    })

    this.PartyForm = this.formBuilder.group({
      gstType: [null],
      gstIn: [ null, [ Validators.minLength(15), Validators.maxLength(15)]],
      partyName: ["", [Validators.required]],
      mobile: [null , [Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$"), Validators.required]],
      email: [null, [Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"), Validators.email, Validators.required]],
      billingAddress: [null, Validators.required],
      shippingAddress: [null],
      balanceStatus: ["1"],
      isCustomer: [false],
    });
  }

  get f() {
    return this.PaymentOutForm.controls;
  }

  get pf() {
    return this.PartyForm.controls;
  }

  returnOnlyCreditInvoices(item?) {
    this.payemntInService.returnOnlyCreditInvoices({}, this.partyId, 'PURCHASE')
      .then((res) => {
        if(res.success){
          this.creditInvoices = res.data;
          this.receiptOptions = this.receiptControl.valueChanges
          console.log(res.data);
          this.receiptOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filterInvoices(name) : this.creditInvoices.slice())
          );
          
          // this.allPartyList = res.data.map(party => ({
          //   partyId: party["party.id"],
          //   partyName: party["party.partyName"]
          // }));
        }
      });
  }

  private filterInvoices(item): string[] {
    return this.creditInvoices.filter(item =>
      item.invoiceNo.indexOf(item) === 0);
  }

  getAllParties() {
    this.payemntInService.returnOnlyCreditvendors({}, this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, 'PURCHASE')
      .then((res) => {
        if(res.success){
          // Transform the response data to remove periods from keys
          this.allPartyList = res.data.map(party => ({
            partyId: party["party.id"],
            partyName: party["party.partyName"]
          }));
          this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filterNames(name) : this.allPartyList.slice())
          );
        }
      });

    // this.partyService
    //   .getAllPartiesDetails({}, this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, this.partyPagesize, (this.currentPageNo + 1))
    //   .then((res) => {
    //     this.allPartyList = res.data.pageData;
    //     this.filteredOptions = this.myControl.valueChanges
    //       .pipe(
    //         startWith(''),
    //         map(name => name ? this.filterNames(name) : this.allPartyList.slice())
    //       );
    //   });
  }

  private filterNames(name: string): string[] {
    return this.allPartyList.filter(partyList =>
      partyList.partyName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  get fp() {
    return this.PartyForm.controls;
  }

  startUpload() {
    document.getElementById('uploadFile').click();
  }

  searchPartyByName(item) {
    this.saleInvoiceService.getPartyByName({}, this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, item)
      .then((response) => {
        this.partyData = response.data.rows;
        this.allPartyList = response.data.rows;
        if (this.allPartyList) {
          this.filteredOptions = this.myControl.valueChanges
            .pipe(
              startWith(''),
              map(name => name ? this.filterNames(name) : this.allPartyList.slice())
            );
        }
      });
  }

  setSelectedPartyDeatails(item) {
    this.myControl.reset(); // Reset control to clear old values
    this.PaymentOutForm.reset();

    setTimeout(() => {
      this.myControl.setValue(item.partyName, { emitEvent: false }); // Set new value without triggering change event
    });
    this.partyId = item.partyId;
    this.returnOnlyCreditInvoices(item.partyId);
  }

  setReceiptDeatails(item) {
    const receiptId = item.id;
    if (item && item.invoiceNo) {
      this.PaymentOutForm.controls['ReceiptNo'].setValue(item.invoiceNo);
      this.PaymentOutForm.get('recieved').setValue(item?.balance)
    }
  }

  postPaymentOutDetails() {
    this.submitted = true;
    if (this.PaymentOutForm.invalid || this.myControl.invalid) {
      console.log("sads",this.PaymentOutForm)
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    } else {
      let body = {
        "payments": {
          "userId": this.currentUser.id,
          "partyId": this.partyId,
          "receiptNumber": this.PaymentOutForm.controls.ReceiptNo.value,
          "paymentType": this.PaymentOutForm.controls.paymentType.value,
          "date": this.todaysDate,
          "description": this.PaymentOutForm.controls.Description.value,
          "image": this.productPicUrl ? this.productPicUrl : null,
          "invoiceType": "PAID",
          "total": parseFloat(this.PaymentOutForm.controls.total.value),
        }
      }
      this.payemntOutService.postPaymentOut(body).then((res) => {
        if (res.success) {
          this.paymentId = res.data.id;
          this.toastService.toastMsg({
            title: "Success",
            content: "Payment Out Added Successfully!",
          });

          this.partyService.notifyPartyAdded();
          this.partyId = "";
          this.todaysDate = "";
          this.submitted = false;
          this.dialogRef.close();
        }
      },
        (err) => {

          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }

        })
    }
  }

  // customertoggleShow(value) {
  //   this.isShowGstFields = value;
  // }

  showShippingAddressinModal(checked: boolean) {
    this.isShippingAddressinModal = checked;
  }

  getGstNumber(gstin) {
    this.partyService.getGstNumber({}, gstin).then((res) => {
      this.gstDetails = res;
      if (res) {
        this.PartyForm.patchValue({
          gstType: res.data.taxPayerType,
          gstNo: res.data.gstIn
        })
      }
      this.isDisabledGST = false;
    }, (err) => {
      this.isDisabledGST = true;
    })
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  validateTRN(TRN) {
    if (TRN.length === 15) {
      this.isDisabledGST = false;
      return true
    } else {
      this.isDisabledGST = true;
      this.PartyForm.controls.gstIn.setErrors({ incorrect: true });
    }
  }


  /**
  * Post party details api call
  */
  // addNewParty() {
  //   this.submittedParty = true;
  //   if (this.isShowGstFields) {
  //     if ((this.PartyForm.controls.billingAddress.value == "" || this.PartyForm.controls.partyName.value == ""
  //       || this.PartyForm.controls.mobile.value == "" || this.PartyForm.controls.email.value == "")) {
  //       this.toastService.toastMsg({
  //         title: "Error",
  //         content: "Fill All Required Fields.",
  //       });
  //       return false;
  //     } else if ((this.PartyForm.controls.billingAddress.value == "" && this.PartyForm.controls.partyName.value == ""
  //       && this.PartyForm.controls.mobile.value == "" && this.PartyForm.controls.email.value == "")) {
  //       this.toastService.toastMsg({
  //         title: "Error",
  //         content: "Fill All Required Fields.",
  //       });
  //     }
  //     else {
  //       this.addPartyObject();
  //     }
  //   }
  //   else {
  //     if ((this.PartyForm.controls.billingAddress.value == "" || this.PartyForm.controls.partyName.value == ""
  //       || this.PartyForm.controls.mobile.value == "" || this.PartyForm.controls.email.value == ""
  //       || this.PartyForm.controls.gstType.value == "" || this.PartyForm.controls.gstIn.value == "")) {
  //       this.toastService.toastMsg({
  //         title: "Error",
  //         content: "Fill All Required Fields.",
  //       });
  //       return false;
  //     }

  //     else {
  //       this.addPartyObject();
  //     }
  //   }
  // }
  // addPartyObject() {

  //   this.finalObject = {
  //     "partyDetails": {
  //       "isCustomer": this.isShowGstFields,
  //       "gstType": this.PartyForm.controls.gstType.value ? this.PartyForm.controls.gstType.value : null,
  //       "gstIn": this.PartyForm.controls.gstIn.value ? this.PartyForm.controls.gstIn.value : null,
  //       "partyName": this.PartyForm.controls.partyName.value,
  //       "mobile": this.PartyForm.controls.mobile.value ? (this.PartyForm.controls.mobile.value).toString() : null,
  //       "email": this.PartyForm.controls.email.value,
  //       "billingAddress": this.PartyForm.controls.billingAddress.value,
  //       "shippingAddress": this.PartyForm.controls.shippingAddress.value,
  //       "openingBalance": null,
  //       "asOfDate": null,
  //       "balanceStatus": this.PartyForm.controls.balanceStatus.value,
  //       "payment": null,
  //       "creditPeriodDay": null,
  //       "creditLimit": null,
  //       "noOfCredits": null,
  //       "relationSince": null,
  //       "userId": this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
  //       "distance": null,
  //       "transporation": null,
  //     },
  //     "groupAdd": null
  //   }

  //   this.partyService.postParty(this.finalObject).then((res) => {
  //     this.paramID = res.data.id
  //     if (res) {
  //       this.toastService.toastMsg({
  //         title: "Success",
  //         content: "Vendor Added Successfully!",
  //       });
  //       this.partyService.notifyPartyAdded();
  //       this.PartyForm.reset();
  //       this.modal.dismissAll();
  //       this.getAllParties();
  //       this.submittedParty = false;
  //     }
  //   },
  //     (err) => {
  //       if (err.error.expose) {
  //         this.toastService.toastMsg({
  //           title: "Error",
  //           content: this.titleCasePipe.transform(err.error.error_message),
  //         });
  //       }
  //       else {
  //         this.toastService.toastMsg({
  //           title: "Error",
  //           content: "Something Went Wrong.",
  //         });
  //       }
  //     })
  // }

  addNewParty() {
    this.submittedParty = true;
    console.log('This.partyForm',this.PartyForm)
    console.log('PartyForm Valid:', this.PartyForm.valid);
    console.log('PartyForm Controls:', this.PartyForm.controls);

    // Use Angular's built-in validation
    if (this.PartyForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return;
    }

    // Check if GST fields should be shown and validate accordingly
    if (this.isShowGstFields) {
      if (!this.PartyForm.controls.billingAddress.value || !this.PartyForm.controls.partyName.value ||
          !this.PartyForm.controls.mobile.value || !this.PartyForm.controls.email.value) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
        return;
      }
    } else {
      if (!this.PartyForm.controls.gstIn.value || !this.PartyForm.controls.billingAddress.value ||
          !this.PartyForm.controls.partyName.value || !this.PartyForm.controls.mobile.value ||
          !this.PartyForm.controls.email.value) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
        return;
      }
    }

    // Proceed with form submission if valid
    this.addPartyObject();
  }

  addPartyObject() {
    console.log("addPartyObject", this.isShowGstFields)
    const finalObject = {
      partyDetails: {
        isCustomer: this.isShowGstFields,
        gstIn: this.PartyForm.controls.gstIn.value ? this.PartyForm.controls.gstIn.value : null,
        partyName: this.PartyForm.controls.partyName.value,
        mobile: this.PartyForm.controls.mobile.value ? this.PartyForm.controls.mobile.value.toString() : null,
        email: this.PartyForm.controls.email.value,
        billingAddress: this.PartyForm.controls.billingAddress.value,
        shippingAddress: this.PartyForm.controls.shippingAddress.value || null,
        openingBalance: null,
        asOfDate: null,
        balanceStatus: this.PartyForm.controls.balanceStatus.value,
        payment: null,
        creditPeriodDay: null,
        creditLimit: null,
        noOfCredits: null,
        relationSince: null,
        userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
        distance: null,
        transporation: null,
        gstType: null,
      },
      groupAdd: null
    };

    console.log('Final Object:', finalObject);

    this.partyService.postParty(finalObject).then((res) => {
      if (res.success) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Contact Added Successfully!",
        });
        this.PartyForm.reset();
        this.modal.dismissAll();
        this.getAllParties();
        this.submittedParty = false;
        this.isShowGstFields = true;
      }
    }).catch((err) => {
      this.toastService.toastMsg({
        title: "Error",
        content: err.error?.expose ? this.titleCasePipe.transform(err.error.error_message) : "Something Went Wrong.",
      });
    });
  }

  customertoggleShow(value) {
    this.isShowGstFields = value;
    console.log("customertoggleShow", value);
    this.isShippingAddressinModal = false;
    this.PartyForm.patchValue({
      gstIn: null, // Assuming you want to clear gstIn as well
      partyName: '',
      mobile: null,
      email: '',
      billingAddress: '',
      shippingAddress: ''
    });

    Object.keys(this.PartyForm.controls).forEach(key => {
      this.PartyForm.get(key)?.markAsUntouched();
    });
    const gstInControl = this.PartyForm.get('gstIn');

    if (this.isShowGstFields) {
      gstInControl.disable();
    } else {
      gstInControl.enable();
    }
  }


  openAddPartyModal(name) {
    this.modal.open(name, {
      centered: true,
      backdrop: "static",
      size: "lg"
    });
  }
  modalDismiss() {
    this.PartyForm.reset();
    this.modal.dismissAll();
  }

  handleInputChange(e, imgNumber?: any) {
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];


    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.PaymentOutForm.patchValue({
          file: reader.result,
        });

        if (file == null) {

          this.toastService.toastMsg({
            title: "Error",
            content: "Please Select Image To Upload.",
          });
        } else {

          this.imageSrc = reader.result;

          formdata.set("upload", file)
          e.srcElement.value = "";
          this.productService.postCoverImage1(formdata).then((res) => {
            this.productPicUrl = res.data.locations[0];

          });
        }
      }
    }
  }
  handleImageLoad() {
    this.imageLoaded = true;
    this.toastService.toastMsg({
      title: "Success",
      content: "Receipt Added Successfully!",
    });
  }
  cancelImage() {
    this.imageSrc = null;
    this.productPicUrl = '';
    this.imageLoaded = false;
    this.toastService.toastMsg({
      title: "Success",
      content: "Receipt removed Successfully!",
    });
  }

  cancel() {
    this.PaymentOutForm.reset();
    this.submitted = false;
    this.dialogRef.close();
  }


  openDatepicker() {
    const selectedDate = this.todaysDate;
    if (isNaN(selectedDate.getTime())) {
      this.todaysDate = new Date();
    }
  }
}
