<div class="modal-header pt-0">
    <h1 style="color: #EB8B3F; font-weight: bolder;margin-left: 5px;" class="modal-title mb-0">Add Warehouse</h1>

    <div class="addProductButtons">
        <button type="button" style="margin-right: 10px;" class="btn btn-cancel" (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-save ms-2" (click)="onSave()"
            *appHasPermission="['create']; name 'warehouse'">Save</button>
    </div>
</div>

<div class="modal-body">
    <form [formGroup]="warehouseForm">
        <div class="row mb-3">
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <label class="form-label">Name<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Name" maxlength="50" formControlName="name" required
                        (keypress)="StringOnly($event)">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.name.touched) && f.name.errors?.required">
                    Name is required.
                </mat-error>
            </div>
        </div>

        <div class="row mb-3">
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Short Name<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Short Name" maxlength="50" formControlName="shortName" required
                        (keypress)="StringOnly($event)">
                </mat-form-field>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.shortName.touched) && f.shortName.errors?.required">
                    Short Name is required.
                </mat-error>
            </div>

            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Business Name<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Business Name" maxlength="50" formControlName="businessName" required>
                </mat-form-field>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.businessName.touched) && f.businessName.errors?.required">
                    Business Name is required.
                </mat-error>
            </div>
        </div>

        <div class="row mb-3">
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label ms-2"> Email Address<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="email" matInput required formControlName="email"
                        pattern="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$" placeholder="Email"
                        [ngClass]="{'is-invalid': f.email.invalid && f.email.touched && submitted}">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.email.touched) &&f.email.errors?.pattern">
                    Enter a valid email id.
                </mat-error>
                <mat-error class="text-danger" *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                    Email is required.
                </mat-error>
            </div>

            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Password<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Password" maxlength="50" formControlName="password"
                        [type]="hide ? 'password' : 'text'" required>
                    <button mat-icon-button matSuffix (click)="hide = !hide" aria-label="Toggle password visibility">
                        <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                    </button>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.password.touched) && f.password.errors?.required">
                    Password is required.
                </mat-error>
            </div>

        </div>

        <div class="row mb-3">
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Contact Person</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Contact Person" maxlength="50" formControlName="contactPerson">
                </mat-form-field>
            </div>

            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Contact #<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Contact #" minlength="10" maxLength="10"
                        (keypress)="numberOnly($event)" formControlName="mobile" required>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.required">
                    Contact # is required.
                </mat-error>
                <mat-error *ngIf="f.mobile.errors?.maxlength">
                    Contact # Must be 10 digits.
                </mat-error>
                <mat-error *ngIf="f.mobile.errors?.minlength">
                    Contact # Must be 10 digits.
                </mat-error>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12">
                <div class="mb-3">
                    <label for="billingAddress" class="form-label">Address<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <textarea matInput id="address" formControlName="address" maxlength="100" required rows="2"
                            placeholder="Billing Address" [ngClass]="{
                                            'is-invalid': f.address.invalid && f.address.touched && submitted
                                        }" autocomplete="off"></textarea>
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.address.touched) && f.address.errors?.required">
                        Enter a valid Address.
                    </mat-error>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-sm-12 col-md-6">
                <mat-label>Pincode</mat-label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" matInput required maxlength="6" minlength="6" name="pincodetxt" id="pincodetxt"
                        formControlName="pincode" placeholder="Pincode" [ngClass]="{
                            'is-invalid': (f.pincode.invalid && f.pincode.touched) && submitted }"
                        (input)="getPincodeDetails($event.target.value)" 
                        (keypress)="numberOnly($event)">

                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.pincode.touched) && f.pincode.errors?.required">
                    Pincode is Required!
                </mat-error>
                <mat-error class="text-danger" *ngIf="(submitted || f.pincode.touched) && f.pincode.errors?.pattern">
                    Pincode should be only digits
                </mat-error>
            </div>
            <div class="col-sm-12 col-md-6">
                <mat-label>State</mat-label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl matInput required type="text" ngxOnlyString required matInput
                        formControlName="state" name="pintxt" id="pintxt" placeholder="State" [ngClass]="{
                            'is-invalid': (f.state.invalid && f.state.touched) && submitted }" value="">
                </mat-form-field>

                <mat-error class="text-danger" *ngIf="(submitted || f.state.touched) && f.state.errors?.required">
                    State is Required!
                </mat-error>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-sm-12 col-md-6">
                <mat-label>City</mat-label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl matInput required type="text" formControlName="district" placeholder="City"
                        [ngClass]="{
                        'is-invalid': (f.district.invalid && f.district.touched) && submitted }" required>
                </mat-form-field>

                <mat-error class="text-danger" *ngIf="(submitted || f.district.touched) && f.district.errors?.required">
                    District is Required!
                </mat-error>
            </div>
            <div class="col-sm-12 col-md-6">
                <mat-label>Country</mat-label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl matInput required type="text" formControlName="country"
                        placeholder="Country" [ngClass]="{
                        'is-invalid': (f.country.invalid && f.country.touched) && submitted }" required>
                </mat-form-field>

                <mat-error class="text-danger" *ngIf="(submitted || f.country.touched) && f.country.errors?.required">
                    Country is Required!
                </mat-error>
            </div>
        </div>
    </form>
</div>