import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/core/services/dashboard.service';

@Component({
  selector: 'app-products-sold',
  templateUrl: './products-sold.component.html',
  styleUrls: ['./products-sold.component.scss'],
})
export class ProductsSoldComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  todaysProductSold: any;
  todaysProductSoldPercent: any;
  newPartiesJoinedCount: any;
  newPartiesPercent: any;
  todaySales: number;
  percentageCount: any;

  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.getTotalSales();
  }

  getTotalSales() {
    this.dashboardService.getProductSold({}, this.currentUser.id, 'SALES')
      .then((res:any) => {
        if (res.data) {
          console.log(res.data);
         this.todaysProductSold =res.data.productDataToday
          this.todaySales = res.data.todaySales;
          this.percentageCount = res.data.extraSalesPercentage;
        }
      });
  }

  generateExcelData() {
    const excelData = [];

    // Column headers
    const columnHeader = ['Metric', 'Value', 'Percentage Change'];
    excelData.push(columnHeader);

    // Data rows
    const dataRows = [
      ['Total Sales', this.todaySales, this.percentageCount],
      ['Product Sold', this.todaysProductSold, this.todaysProductSoldPercent],
      ['New Customers', this.newPartiesJoinedCount, this.newPartiesPercent],
    ];

    excelData.push(...dataRows);
    // dataRows.forEach(row => {
    //   excelData.push(row);
    // });

    return excelData;
  }
}
