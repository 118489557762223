<div class="container-fluid mt-1" style="background-color: white;">
  <div class="row h-100">
    <div class="col-md-12 col-lg-6 mt-4 pe-0" style="overflow-y: auto;">
      <!-- <table class="table table-responsive table-bordered">
        <thead>
          <tr>
            <th>Denomination</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1000's</td>
            <td>11</td>
          </tr>
          <tr>
            <td>500's</td>
            <td>11</td>
          </tr>
          <tr>
            <td>200's</td>
            <td>11</td>
          </tr>
          <tr>
            <td>100's</td>
            <td>11</td>
          </tr>
          <tr>
            <td>50's</td>
            <td>11</td>
          </tr>
          <tr>
            <td>10's</td>
            <td>11</td>
          </tr>
          <tr>
            <td>5's</td>
            <td>11</td>
          </tr>
          <tr>
            <td></td>
            <td>77000</td>
          </tr>
        </tbody>
      </table> -->
      <form [formGroup]="denominationForm">
        <table class="table table-responsive table-bordered">
          <thead>
            <tr>
              <th>Denomination</th>
              <th>Count</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody formArrayName="denominationsArray">
            <tr *ngFor="let group of denominationsArray.controls; let i = index" [formGroupName]="i">
              <td>{{ denominations[i] }}'s</td>
              <td>
                <input class="table-input p-1" formControlName="count" type="number" min="0">
              </td>
              <td>{{ denominations[i] * group.value.count }}</td>
            </tr>
            <tr>
              <td colspan="2"><strong>Total Value</strong></td>
              <td><strong>{{ getTotal() }}</strong></td>
            </tr>
          </tbody>
        </table>
      </form>
      
    </div>
    <div class="col-md-12 col-lg-6 mt-4 ps-0" style="overflow-y: auto;">
      <table class="table table-responsive table-bordered">
        <thead>
          <tr>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div style="display: flex; justify-content: space-between; align-items: center;">
                <div>Cash open amount</div>
                    <input type="number" [(ngModel)]="cashOpenAmount" class="form-control amount" style="width: 200px; text-align: right; padding-right: 30px;" placeholder="0.00" 
                    (keypress)="numberOnly($event)" min="0"/>
                    <span style="position: absolute; right: 35px; top: 19.2%; transform: translateY(-100%);">₹</span>
                </div>
            </td>
        </tr>
        <tr>
            <td>
              <div style="display: flex; justify-content: space-between; align-items: center;">
                    <div>Cash sale</div>
                    <input type="number" [(ngModel)]="cashSale" class="form-control amount" style="width: 200px; text-align: right; padding-right: 30px;" placeholder="0.00" 
                    (keypress)="numberOnly($event)"min="0"/>
                    <span style="position: absolute; right: 35px; top: 31%; transform: translateY(-100%);">₹</span>
                </div>
            </td>
        </tr>
        
          <tr>
            <td>
              <div style="display: flex;justify-content: space-between;">
                <div>Cash total addition</div>
                <div class="amount">{{ (totalAdd || 0) | number : "1.2-2" }}₹</div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="display: flex;justify-content: space-between;">
                <div>Cash total subtration</div>
                <div class="amount">{{ (totalSubtract || 0) | number : "1.2-2" }} ₹</div>
              </div>
            </td>
          </tr>
          <tr style="background-color: rgb(239, 178, 156);">
            <td>
              <div style="display: flex;justify-content: space-between;">
                <div style="color:black;">You should have this amount in your register: </div>
                <div style="font-size: 18px;font-weight: bold;color:greenyellow">{{ (totalamount || 0) | number : "1.2-2" }} ₹</div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="display: flex;justify-content: space-between;align-items: center;">
                <label for="register" style="font-size:14px;font-weight: bold;color:black;">Cash closing amount<span class="error-asterisk">*</span></label>
                <div class="form-group">
                  <mat-form-field appearance="outline" style="min-width:320px;">
                    <input matInput id="register" placeholder="Enter Amount" [(ngModel)]="closingBalance" maxlength="8">
                  </mat-form-field>
                  <mat-error class="text-danger" *ngIf="submitted && isClosingBalance()">
                    Closing amount is required.
                  </mat-error>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="display: flex;justify-content: space-between;">
                <div>Cash open amount</div>
                <div class="amount">{{ (cashOpenAmount || 0) | number : "1.2-2" }} ₹</div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="display: flex;justify-content: center;align-items: center;height:60px;">
                <div style="flex-basis:60%;display: flex;justify-content: space-between;align-items:center;">
                  <button type="button" class="btn-save" (click)="save()">&nbsp;&nbsp;save&nbsp;&nbsp;</button>
                  <div style="font-size: 18px;font-weight: bold;">OR</div>
                  <button type="button" class="btn  btn-success" style="height:35px;" (click)="logout()">Logout without closing</button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
