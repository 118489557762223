import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ProductService } from 'src/app/core/services/product.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';
import { Location, TitleCasePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import lang from "src/assets/langTranslation/language_translation"
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-product-service-update-list',
  templateUrl: './product-service-update-list.component.html',
  styleUrls: ['./product-service-update-list.component.scss']
})
export class ProductServiceUpdateListComponent implements OnInit {
  public lang = lang.UAE;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public unitValue: any;
  public unitSecondaryValue: any;
  public isDisabled: boolean = false;
  public showProductType: any;
  public serviceToggle: boolean = true;
  public isStockToggle: boolean = true;
  public ProductForm: any;
  public productId: string = "";
  public todaysDate;
  public unit: any;
  public units: any;
  public secondaryunit: any;
  // image upload
  public activeColor: string = 'green';
  public baseColor: string = '#ccc';
  public overlayColor: string = 'rgba(255,255,255,0.5)';
  public dragging: boolean = false;
  public loaded: boolean = false;
  public imageLoaded: boolean = false;
  public imageSrc: string = '';
  public file: any;
  public selectedFiles: any;
  public selectedFileName: any;
  public imageSrcOne: any = "";
  public imageSrcOneId: any;
  public imageSrcTwo: any = "";
  public imageSrcTwoId: any;
  public imageSrcThree: any = "";
  public imageSrcThreeId: any = ""
  public imageSrcFour: any = "";
  public imageSrcFourId: any = "";
  public imageSrcFive: any = "";
  public imageSrcFiveId: any = "";
  public imageSrcSix: any = "";
  public imageSrcSixId: any = "";
  public imageSrcSeven: any = "";
  public imgNumber: any;
  public submitted: boolean = false;
  public productCategory: any;
  public categoryIddd: any = 0;
  public currentInput: any;
  public multipleImage = [];
  public multipleImage1 = [];
  previews: string[] = [];
  public fetchImages: any;
  imageArray: any;
  public coverImageChange: boolean = false;
  blankImage = [];
  productPicUrl: any;
  public maxFiles: number = 0;
  public Filesmax: number = 5;

  constructor(private productService: ProductService, private commonService: CommonService,
    private formBuilder: FormBuilder,
    private titleCasePipe: TitleCasePipe,
    public dialogRef: MatDialogRef<ProductServiceUpdateListComponent>,
    @Inject(MAT_DIALOG_DATA) public serviceData: any,

    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private toastService: ToastNotificationService) { }

  ngOnInit(): void {

    this.productId = this.serviceData.productId
    this.ProductForm = this.formBuilder.group({
      serviceName: [""],
      serviceCode: ["", [Validators.required, Validators.minLength(4), Validators.maxLength(8), Validators.pattern('^[a-zA-Z0-9]*$')]],
      category: [""],
      description: [""],
      unit: [""],
     // generateBarcode: ["", [Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern("^[0-9]*$")]],
      hsn: [null],
      price: [""],
      gstRate: ["5",],
      secondaryUnit: [""],
      conversionRate: [""],
     // openingStock: [],
      asOfDate: ["",[Validators.required]],
      additionalCess: [""]

    });
    this.getServiceById();
    this.getProductCategory();
  }

  get f() {
    return this.ProductForm.controls;
  }

  SecondaryUnit = Constants.Units
  Units = Constants.Units
  getUnit(value) {
    this.unitValue = "1 " + value;
  }

  getEnableStockValue(event) {
    this.isStockToggle = event;
  }

  showSecondaryUnitField(value) {
    this.isDisabled = value;
  }

  getSecondaryUnit(value) {
    this.unitSecondaryValue = value;
  }

  show(id) {
    if (id === 5) {
      this.showProductType = Constants.USER.GOODS;
      this.serviceToggle = true;
      //this.disabled = false;
    }
    else if (id === 6) {
      this.showProductType = Constants.USER.SERVICE;
      this.serviceToggle = true;

    }
  }
  getProductCategory() {
    this.productService.getCategory({
      data:{}, 
      userId: this.currentUser.id, 
      size: 1000, 
      page: (1), 
      search:'', 
      isActive: '' 
    }).then((res: any) => {
      this.productCategory = res.data.pageData;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })

      }
    })
  }
  getServiceById() {
    this.isDisabled = true
    this.productService.getServiceById({}, this.productId).then((res) => {
      this.ProductForm.patchValue({
        serviceName: res.data.itemName,
        category: res.data.category.id,
        serviceCode: res.data.barcode,
       // generateBarcode: res.data.barcode,
        description: res.data.description,
        hsn: res.data.hsn,
        price: res.data.salePrice,
        gstRate: res.data.gstRate,
       // openingStock: res.data.stock,
        asOfDate: (moment(res.data.asOfDate).format("DD MMM YYYY")),
        additionalCess: res.data.additionalCess
      })
      this.imageSrcSeven = res.data.coverImage ? res.data.coverImage : null;
      this.fetchImages = res.data.productsImages;
      this.maxFiles = res.data.productsImages.length;

    })
  }
  serviceUpdate() {
    this.submitted = true;
    if (this.ProductForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    }
    else {
      let data = {
        "serviceDetails": {
          "userId": this.currentUser.id,
          "itemName": this.ProductForm.controls.serviceName.value,
          "categoryId": this.ProductForm.controls.category.value,
          "barcode": this.ProductForm.controls.serviceCode.value,
          "description": this.ProductForm.controls.description.value,
          "hsn": this.ProductForm.controls.hsn.value,
          "salePrice": this.ProductForm.controls.price.value,
          "gstRate": this.ProductForm.controls.gstRate.value,
         // "stock": this.ProductForm.controls.openingStock.value,
          "asOfDate": moment(this.ProductForm.controls.asOfDate.value, 'DD MMM YYYY').format('YYYY-MM-DD'),
          "additionalCess": this.ProductForm.controls.additionalCess.value,
          "coverImage": this.productPicUrl ?  this.productPicUrl : null
        },
        "productImage":
          this.imageArray ? this.imageArray : this.blankImage,

      }

      let data1 = {
        "serviceDetails": {
          "userId": this.currentUser.id,
          "itemName": this.ProductForm.controls.serviceName.value,
          "categoryId": this.ProductForm.controls.category.value,
          //"barcode": this.ProductForm.controls.generateBarcode.value,
          "barcode": this.ProductForm.controls.serviceCode.value,
          "description": this.ProductForm.controls.description.value,
          "hsn": this.ProductForm.controls.hsn.value,
          "salePrice": this.ProductForm.controls.price.value,
          "gstRate": this.ProductForm.controls.gstRate.value,
        //  "stock": this.ProductForm.controls.openingStock.value,
          "asOfDate": moment(this.ProductForm.controls.asOfDate.value, 'DD MMM YYYY').format('YYYY-MM-DD'),
          "additionalCess": this.ProductForm.controls.additionalCess.value,
        },
        "productImage":
          this.imageArray ? this.imageArray : this.blankImage,

      }

      if (this.coverImageChange == true) {
        this.productService.serviceUpdate(data, this.productId).then((res) => {
          if (res) {
            this.toastService.toastMsg({
              title: "Success",
              content: "Service Updated Successfully!",
            });
            this.productService.notifyProductAdded();
            this.dialogRef.close();
          }
        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        })

      }
      else {
        this.productService.serviceUpdate(data1, this.productId).then((res) => {
          if (res) {
            this.toastService.toastMsg({
              title: "Success",
              content: "Service Updated Successfully!",
            });
            this.productService.notifyProductAdded();
            this.dialogRef.close();
          }

        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        })

      }

    }

  }

  cancel() {
    this.dialogRef.close();
  }

  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }
  handleInputChange(e, imgNumber?: any) {
    this.imgNumber = imgNumber;
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];

    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    this.loaded = false;
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.ProductForm.patchValue({
          file: reader.result,
        });
        if (file == null) {

          this.toastService.toastMsg({
            title: "Error",
            content: "Please Select Image To Upload.",
          });
        } else {
          if (this.imgNumber == 1) {
            formdata.set("uploadProductImage", file)

          } else if (this.imgNumber == 2) {
            formdata.set("uploadProductImage", file)

          } else if (this.imgNumber == 3) {
            formdata.set("uploadProductImage", file)

          } else if (this.imgNumber == 4) {
            formdata.set("uploadProductImage", file)

          } else if (this.imgNumber == 5) {
            formdata.set("uploadProductImage", file)

          } else if (this.imgNumber == 6) {
            formdata.set("uploadProductImage", file)

          } else if (this.imgNumber == 7) {
            this.imageSrcSeven = reader.result;
            formdata.set("uploadProductImage", file)
          }
          this.productService.postProductImage(formdata, this.productId).then((res) => {
            if (res) {
              this.getProductImage();
              this.getServiceById();
            }
          });
        }
      }
    };
  }

  getProductImage() {
    this.productService.getProductImage({}, this.productId).then((res) => {
      if (res) {
        let productImage = res.data;
        for (let i = 0; i <= productImage.length; i++) {
          if (i == 0) {
            this.imageSrcOne = productImage[0].productImage;
          }
          if (i == 1) {
            this.imageSrcTwo = productImage[1].productImage;
          }
          if (i == 2) {
            this.imageSrcThree = productImage[2].productImage;
          }
          if (i == 3) {
            this.imageSrcFour = productImage[3].productImage;
          }
          if (i == 4) {
            this.imageSrcFive = productImage[4].productImage;
          }
          if (i == 5) {
            this.imageSrcSix = productImage[5].productImage;
          }
        }
      }
    })
  }

  handleCoverDragEnter() {
    this.dragging = true;
  }
  handleCoverDragLeave() {
    this.dragging = false;
  }
  handleCoverImageLoad() {
    this.imageLoaded = true;
  }
  handleCoverDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.coverImgChange(e);
  }
  coverImgChange(e, imgNumber?: any) {
    this.imgNumber = imgNumber;
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];


    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    const fileSizeLimit = 1 * 1024 * 1024; // 6 MB
    if (file.size > fileSizeLimit) {
      alert("File size exceeds the limit. Please select a smaller image (up to 1 MB).");
      return;
    }
    else {
      this.loaded = false;
      if (e.target.files && e.target.files[0]) {
        reader.readAsDataURL(file);

        reader.onload = () => {
          this.ProductForm.patchValue({
            file: reader.result,
          });

          if (file == null) {

            this.toastService.toastMsg({
              title: "Error",
              content: "Please Select Image To Upload.",
            });
          } else {

            this.imageSrcSeven = reader.result;

            formdata.set("upload", file);
            e.srcElement.value = "";
            this.productService.postCoverImage1(formdata).then((res) => {
              this.coverImageChange = true;
              this.productPicUrl = res.data.locations[0];
              this.toastService.toastMsg({
                title: "Success",
                content: "Image Uploaded Successfully!",
              });

            });
          }
        }
      }
    }

  }
  cancelCoverImageCover() {
    this.productService.deleteproductCoverImgId({}, this.productId).then((res) => {
      this.toastService.toastMsg({
        title: "Success",
        content: "Cover Image Deleted Successfully!",
      });
      this.imageSrcSeven = '';
      this.getServiceById();
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went To Wrong.",
        });
      }
    });
  }
  cancelImage(id: any) {
    this.DeleteProductImg(id);
  }
  DeleteProductImg(imageId) {
    this.productService.deleteproductImgId({}, imageId).then((res) => {
      this.toastService.toastMsg({
        title: "Success",
        content: "Image Deleted Successfully!",
      });
      this.getServiceById();

    }
      , (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went To Wrong.",
          });
        }
      });


  }
  startUpload() {
    document.getElementById('uploadCoverPic').click();
  }
  startUploadImageSix() {
    document.getElementById('imageSrcSixId').click();
  }
  startUploadImageFive() {
    document.getElementById('imageSrcFive').click();
  }
  startUploadImageFour() {
    document.getElementById('imageSrcFour').click();
  }
  startUploadImageThree() {
    document.getElementById('imageSrcThree').click();
  }
  startUploadImageTwo() {
    document.getElementById('imageSrcTwo').click();
  }
  startUploadImageOne() {
    document.getElementById('imageSrcOne').click();
  }

  onFileSelected(event) {
    // const maxFileSize = 1024 * 1024; // 1 MB
    // const selectedFiles = event.target.files;
    // if (this.maxFiles == 5) {
    //   this.toastService.toastMsg({
    //     title: "Error",
    //     content: "You Have All Ready Select ${this.maxFiles} Images.",
    //   });
    // }
    // else {
    //   if (selectedFiles.length > (this.Filesmax - this.maxFiles)) {

    //     this.toastService.toastMsg({
    //       title: "Error",
    //       content: "You Can Only Select Up To ${this.Filesmax - this.maxFiles} Images.",
    //     });
    //     return;
    //   }

    //   for (let i = 0; i < selectedFiles.length; i++) {
    //     const file = selectedFiles[i];

    //     if (file.size > maxFileSize) {
    //       // Handle file size too large error

    //       this.toastService.toastMsg({
    //         title: "Error",
    //         content: "Image ${file.name} Exceeds The Maximum Size Of 1 MB.",
    //       });
    //       return;
    //     }
    //   }

    //   // Continue with file processing
    //   const frmData = new FormData();
    //   for (let i = 0; i < selectedFiles.length; i++) {
    //     const reader = new FileReader();
    //     reader.onload = (e: any) => {
    //       this.previews.push(e.target.result);
    //     };
    //     reader.readAsDataURL(selectedFiles[i]);
    //     frmData.append("upload", selectedFiles[i]);
    //   }
    //   this.maxFiles = this.maxFiles + selectedFiles.length;

    //   this.uploadMultiplefile(frmData);
    // }
    const maxFileSize = 1024 * 1024; // 1 MB
    const selectedFiles = event.target.files;
    if (this.maxFiles == 5) {
      this.toastService.toastMsg({
        title: "Error",
        content: `You Have Already Selected ${this.maxFiles} Images.`,
      });
      event.srcElement.value = "";
    }
    else {
      if (selectedFiles.length > (this.Filesmax - this.maxFiles)) {

        this.toastService.toastMsg({
          title: "Error",
          content: `You Can Only Select Up To ${this.Filesmax - this.maxFiles} Images.`,
        });
        event.srcElement.value = "";
        return;
      }

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        if (file.size > maxFileSize) {
          // Handle file size too large error

          this.toastService.toastMsg({
            title: "Error",
            content: `Image ${file.name} Exceeds The Maximum Size Of 1 MB`
          });
          event.srcElement.value = "";
          return;
        }
      }

      // Continue with file processing
      const frmData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };
        reader.readAsDataURL(selectedFiles[i]);
        frmData.append("upload", selectedFiles[i]);
      }
      this.maxFiles = this.maxFiles + selectedFiles.length;
      this.uploadMultiplefile(frmData);
      event.srcElement.value = "";
    }

  }
  uploadMultiplefile(frmData: FormData) {

    // this.productService.postCoverImage1(frmData).then((res) => {
    //   if (res) {
    //     if (this.multipleImage.length == 0) {
    //       this.multipleImage = res.data.locations;
    //       let imageArray = [];

    //       for (var i = 0; i < this.multipleImage.length; i++) {
    //         let obj = {
    //           "productId": this.productId,
    //           "productImage": this.multipleImage[i]
    //         };
    //         imageArray.push(obj);

    //       }
    //       this.imageArray = imageArray;

    //     }
    //     else {
    //       this.multipleImage1 = res.data.locations;
    //       for (var i = 0; i < this.multipleImage1.length; i++) {
    //         this.multipleImage.push(this.multipleImage1[i]);
    //       }

    //       let imageArray = [];

    //       for (var i = 0; i < this.multipleImage.length; i++) {
    //         let obj = {
    //           "productId": this.productId,
    //           "productImage": this.multipleImage[i]
    //         };
    //         imageArray.push(obj);

    //       }
    //       this.imageArray = imageArray;


    //     }

    //     this.toastService.toastMsg({
    //       title: "Success",
    //       content: "Product Images Added Successfully!",
    //     });


    //   }

    // }, (err) => {
    //   if (err.error.expose) {
    //     this.toastService.toastMsg({
    //       title: "Success",
    //       content: this.titleCasePipe.transform(err.error.error_message),
    //     });
    //   }
    //   else {
    //     this.toastService.toastMsg({
    //       title: "Error",
    //       content: "Something Went To Wrong.",
    //     });
    //   }
    // });
    this.productService.postCoverImage1(frmData).then((res) => {
      if (res) {
        if (this.multipleImage.length == 0) {
          this.multipleImage = res.data.locations;
          let imageArray = [];

          for (var i = 0; i < this.multipleImage.length; i++) {
            let obj = {
              "productId": this.productId,
              "productImage": this.multipleImage[i]
            };
            imageArray.push(obj);

          }
          this.imageArray = imageArray;

        }
        else {
          this.multipleImage1 = res.data.locations;
          for (var i = 0; i < this.multipleImage1.length; i++) {
            this.multipleImage.push(this.multipleImage1[i]);
          }

          let imageArray = [];

          for (var i = 0; i < this.multipleImage.length; i++) {
            let obj = {
              "productId": this.productId,
              "productImage": this.multipleImage[i]
            };
            imageArray.push(obj);

          }
          this.imageArray = imageArray;


        }

        this.toastService.toastMsg({
          title: "Success",
          content: "Product Images Added Successfully!",
        });
      }

    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went To Wrong.",
        });
      }
    });


  }

  cancelmultiImage(i: number) {

    this.previews.splice(i, 1); // Removes one element, starting from index
    this.maxFiles = this.maxFiles - 1; // Decrement maxFiles by 1
    this.imageArray.splice(i, 1);
    this.toastService.toastMsg({
      title: "Success",
      content: "Image removed successfully!",
    });
  }

  cancelCoverImage(id: any) {
    this.DeleteProductImg(id);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  numberDecimalOnly(event): boolean {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
  openDatepicker() {
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate = this.ProductForm.get('asOfDate').value;
    if (isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here
      this.ProductForm.get('asOfDate').setValue(new Date());
    }
  }
  // autoGenerate() {
  //   this.productService.autoGenerateBarcode({}, this.currentUser.id).then((codeData) => {
  //     if (codeData) {
  //       this.ProductForm.patchValue({
  //         generateBarcode: codeData.data
  //       })
  //     }
  //   }, (err) => {
  //     if (err.error.expose) {
  //       this.toastService.toastMsg({
  //         title: "Error",
  //         content: this.titleCasePipe.transform(err.error.error_message),
  //       });
  //     }
  //     else {
  //       this.toastService.toastMsg({
  //         title: "Error",
  //         content: "Something Went Wrong.",
  //       });
  //     }
  //   })
  // }



}
