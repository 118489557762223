import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BiddingService } from 'src/app/core/services/bidding.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';
import { StateConstants } from 'src/app/_helpers/state-constans';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  public allStates = StateConstants.states;

  @Input() warnTxt;
  @Input() chkBoxTxt;
  @Input() isChkbox;
  @Input() id;

  public wishlistForm = this.formBuilder.group({
    budgetMax: ["", [Validators.required]],
    budgetMin: ["", [Validators.required]],
    pincode: ["", [Validators.required]],
    location: ["", [Validators.required]],
    confirm: ["", [Validators.required]],
    publish:["",[Validators.required]]
  })
  public todaysDate = this.commonService.getTodaysDate();

  constructor(private biddingService: BiddingService,
    private notificationService: ToastNotificationService,
    private modal: NgbModal,
    private formBuilder: FormBuilder,
    private toastService: ToastNotificationService,
    private commonService: CommonService) { }

  ngOnInit(): void {
  }

  get f() {
    return this.wishlistForm.controls;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  /**
   * Request bid / Wishlist
   */
  postwishlistBid() {
    let data = {
      "userId": this.currentUser.id,
      "publish": this.wishlistForm.controls.publish.value,
      "productId": this.id,
      "budgetMax": this.wishlistForm.controls.budgetMax.value,
      "location": this.wishlistForm.controls.location.value,
      "pincode": this.wishlistForm.controls.pincode.value,
      "approxCompletedDate": this.todaysDate,
      "budgetMin": this.wishlistForm.controls.budgetMin.value,
    }
    this.biddingService.postwishlistBid(data).subscribe((res) => {
      if (res) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Bid Request Submitted Successfully!",
        });
        // this.notificationService.openSnackBar("Bid request submitted successfully..");
      }
    })
  }
  modalDismiss() {
   // this.bankForm.reset();
    this.modal.dismissAll();
   // this.isEdit = false;
  }
}
