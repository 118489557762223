import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from 'src/app/core/services/dashboard.service';

@Component({
  selector: 'app-total-sales',
  templateUrl: './total-sales.component.html',
  styleUrls: ['./total-sales.component.scss'],
})
export class TotalSalesComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  todaySales: number;
  percentageCount: any;

  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.getTotalSales();
  }
  getTotalSales() {
    this.dashboardService
      .getTotalSales({}, this.currentUser.id, 'SALES')
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          this.todaySales = res.data.todaySales;
          this.percentageCount = res.data.percentageChange;
        }
      });
  }
}
